import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import images from '../images';

export default function Sidebar(props) {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [active, setactive] = useState({});

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const selectoptions = [
        { value: 'All', label: 'All' },
        { value: 'FY', label: 'Financial Year' },
        { value: 'CY', label: 'Calendar year' },
        { value: 'Custom', label: 'Custom' },
    ]
    const FY = [
        { value: '2020-2021', label: '2020-2021' },
        { value: '2021-2022', label: '2021-2022' },
        { value: '2022-2023', label: '2022-2023' },
    ]

    const CY = [
        { value: '2024', label: '2024' },
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' },
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
        { value: '2019', label: '2019' },
    ]
    const onSelect = (data) => {
        setactive(data)
    }
    const clearfilter = () => {
        setactive("")
    }
    return (
        <div className='admin_sidebar md'>
            <div className='report_sidebar'>
                <div className='report_sidebar_container'>
                    <div className='dashboard_card_header'>
                        <h5 className='font14 sbold mb-0'>Regulations</h5>
                    </div>
                    <div className='report_sidebar_container'>
                        <div className='d-flex justify-content-between mb-3'>
                            <p className='mb-0 sbold font12'>Filter</p>
                            <button className='primary-text font12' onClick={clearfilter}>Clear</button>
                        </div>
                        <div className='report_filters'>
                            <p className='font12 mb-2'>Period</p>
                            <div className='custom_select w-100'>
                                <Select
                                    options={selectoptions}
                                    value={active || selectoptions[0].value}
                                    className='w-100'
                                    classNamePrefix="select"
                                    onChange={onSelect}
                                    menuPlacement='auto'
                                    isSearchable={false}
                                />
                                {active.value == "FY" ?
                                    <Select
                                        options={FY}
                                        defaultValue={FY[0].value}
                                        className='w-100 mt-3'
                                        classNamePrefix="select"
                                        menuPlacement='auto'
                                        isSearchable={false}
                                    />
                                    : null
                                }
                                {active.value == "CY" ?
                                    <Select
                                        options={CY}
                                        defaultValue={CY[0].value}
                                        className='w-100 mt-3'
                                        classNamePrefix="select"
                                        menuPlacement='auto'
                                        isSearchable={false}
                                    />
                                    : null
                                }
                                {active.value == "Custom" ?
                                    <div className='d-flex common_btn btn_light2 mt-3 px-2'>
                                        <img src={images.event_note} alt='' width={12} className='me-2' />
                                        <DatePicker
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText='Select Range'
                                            className='custom_range'
                                            dateFormat="dd/MM/yy"
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <button className='common_btn w-100'>Apply Filter</button>
                </div>
            </div>
        </div>
    )
}
