import React, { useState } from 'react'
import "./CustomerSupport.css"
import { Link } from 'react-router-dom'
import images from '../images' 

export default function CustomerSupport() {
 
    return (
        <div className="forgotPassword ">

            <div className='customCard customCard--large'>
                <div className='customCard__heading  '>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <h6 className='font20 sbold headingWithImg'>
                                <Link to="/" className='me-2' ><img src={images.backArrow} width="15" /></Link>Customer Support</h6>
                        </div>
                        <div>
                          
                                <Link to='/support/call'  className='p-1'><img src={images.call} width="18" /></Link> 
                           
                        </div>
                    </div>
                    <hr />
                </div>
                <div>
                    <div className='customCard__form'>

                        <div className='chatCard chatCard--left medium text-black'>Hi, How can we help you? Please choose your option you are having problem with...</div>
                        <div className='chatCard chatCard--hintBox medium '>
                            <button> I can’t Login in my Account</button>
                            <button> I forgot my credentials</button>
                            <button> My Registered Credentials are showing Invalid</button>
                        </div>

                        <div className='chatCard chatCard--right medium'>I forgot my credentials</div>
                        <div className='chatCard chatCard--left medium text-black'>No Worries! We have got your back, Try Forgot password, reset your password old password</div>
                        <div className='chatCard chatCard--right medium'>No, I’m good</div>
                        <div className='chatCard chatCard--left medium text-black'>Happy to help you! Anything more we could help you with?</div>

                        <div className='chatCard chatCard--hintBox medium '>
                            <button>Reset my Password</button>
                            <button> No I’m good</button>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}
