import React from 'react'
import images from '../images'

export default function ReportData(props) {
    return (
        <div className='  mt-4'>
            <div className='dashboard_card'>
              
                {/* <hr className='mb-0' /> */}
                <div className="policy_table w-100">
                    <table className='table sort_table'>
                        <thead>
                            <tr>
                                <th>Report Name</th>
                                <th>Date of Publish</th>
                                <th className='empty'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((opt, i) => {
                                return (
                                    <tr key={i}>
                                        <td width={"40%"}>
                                            <div className='d-flex align-items-center'> 
                                                <p className='med text-black mb-0'>{opt.name}</p>
                                            </div>
                                        </td>
                                        <td width={"20%"}>
                                            <p className='med mb-0'>{opt.date}</p>
                                        </td>
                                        <td className='text-end'>
                                            <button className='font14 primary-text'>View
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.8417 11.4844L9.40536 9.04805C9.27715 8.91986 9.21205 8.76421 9.21005 8.58112C9.20804 8.39803 9.27314 8.24039 9.40536 8.10818C9.53757 7.97596 9.69421 7.90985 9.8753 7.90985C10.0564 7.90985 10.213 7.97596 10.3452 8.10818L13.1854 10.9483C13.2647 11.0276 13.3216 11.1102 13.356 11.1959C13.3905 11.2816 13.4077 11.3778 13.4077 11.4844C13.4077 11.5909 13.3905 11.6871 13.356 11.7728C13.3216 11.8586 13.2647 11.9411 13.1854 12.0204L10.3452 14.8606C10.217 14.9888 10.0614 15.0539 9.8783 15.0559C9.69521 15.0579 9.53757 14.9928 9.40536 14.8606C9.27314 14.7283 9.20703 14.5717 9.20703 14.3906C9.20703 14.2095 9.27314 14.0529 9.40536 13.9207L11.8417 11.4844Z" fill="#6FC72E" /> </svg>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
