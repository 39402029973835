import React from 'react'
import images from '../images'
import "./Footer.css"
export default function Footer() {
    return (
        <div className='mainFooter'>
            <div className='mainFooter__top'>
                <div className='container'>
                    <div className='mainFooter__topBox d-flex justify-content-between align-items-center'>
                        <div className='loginFooterTop__left'>
                            <img src={images.logogrey} alt="" />
                        </div>
                        <div className='loginFooterTop__right'>Contact us on  <a href="tel:+919876543210"></a> +91 9876543210</div>
                    </div>
                </div>
            </div>
            <div className='mainFooter__bottom'>
                <div className='container'>
                    <div className='mainFooter__bottomBox d-flex justify-content-between align-items-center'>
                        <div className='loginFooter'>Powered by Coveradda</div>
                        <div className='loginFooter'>Copyright © 2010-2023 Coveradda Co. Ltd All rights reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
