export default {
    logo: require("../../assets/images/logo.svg").default, 
    logo_trans: require("../../assets/images/private/logo-main.png"), 
    emergency_home: require("../../assets/images/private/emergency_home.svg").default, 
    event_note: require("../../assets/images/private/event_note.svg").default, 
    folder_open: require("../../assets/images/private/folder_open.svg").default, 
    notifications: require("../../assets/images/private/notifications.svg").default, 
    partly_cloudy_day: require("../../assets/images/private/partly_cloudy_day.svg").default, 
    search: require("../../assets/images/private/search.svg").default, 
    arrow_back: require("../../assets/images/private/arrow_back.svg").default, 
    expand_all: require("../../assets/images/private/expand_all.svg").default, 
    expand_circle_right: require("../../assets/images/private/expand_circle_right.svg").default, 
    podium: require("../../assets/images/private/podium.svg").default, 
    bar_chart: require("../../assets/images/private/bar_chart.svg").default, 
    add_notes: require("../../assets/images/private/add_notes.svg").default, 
    receipt_long: require("../../assets/images/private/receipt_long.svg").default, 
    task_alt: require("../../assets/images/private/task_alt.svg").default, 
    mouse: require("../../assets/images/private/mouse.svg").default, 
    map_large: require("../../assets/images/private/map_large.svg").default, 
    close: require("../../assets/images/private/close.svg").default, 
    person_apron: require("../../assets/images/private/person_apron.svg").default, 
    directions_boat: require("../../assets/images/private/directions_boat.svg").default, 
    security: require("../../assets/images/private/security.svg").default, 
    local_fire_department: require("../../assets/images/private/local_fire_department.svg").default, 
    category: require("../../assets/images/private/category.svg").default, 
    dangerous: require("../../assets/images/private/dangerous.svg").default, 
    claim_arrow_right: require("../../assets/images/private/claim_arrow_right.svg").default, 
    navigate_next: require("../../assets/images/private/navigate_next.svg").default, 
    download: require("../../assets/images/private/download.svg").default, 
    draft: require("../../assets/images/private/draft.svg").default, 
    activity: require("../../assets/images/private/activity.svg").default, 
    verified_user: require("../../assets/images/private/verified_user.svg").default, 
    real_estate_agent: require("../../assets/images/private/real_estate_agent.svg").default, 
    domino_mask: require("../../assets/images/private/domino_mask.svg").default, 
    directions_car: require("../../assets/images/private/directions_car.svg").default, 
    ecg_heart: require("../../assets/images/private/ecg_heart.svg").default, 
    assignment_late: require("../../assets/images/private/assignment_late.svg").default, 
    contract: require("../../assets/images/private/contract.svg").default, 
    assignment_turned_in: require("../../assets/images/private/assignment_turned_in.svg").default, 
    beenhere: require("../../assets/images/private/beenhere.svg").default, 
    format_list_bulleted: require("../../assets/images/private/format_list_bulleted.svg").default, 
    store: require("../../assets/images/private/store.svg").default, 
    public: require("../../assets/images/private/public.svg").default, 
    shield: require("../../assets/images/private/shield.svg").default, 
    gpp_maybe: require("../../assets/images/private/gpp_maybe.svg").default, 
    event: require("../../assets/images/private/event.svg").default, 
    calendar_clock: require("../../assets/images/private/calendar_clock.svg").default, 
    user: require("../../assets/images/private/user.png"), 
    event_img: require("../../assets/images/private/event_img.jpg"), 
    event1: require("../../assets/images/private/event1.jpg"),
    event2: require("../../assets/images/private/event2.jpg"),
    balance: require("../../assets/images/private/balance.svg").default,
    person: require("../../assets/images/private/person.svg").default,
    groups_2: require("../../assets/images/private/groups_2.svg").default,
    corporate_fare: require("../../assets/images/private/corporate_fare.svg").default,
    widgets: require("../../assets/images/private/widgets.svg").default,
    location: require("../../assets/images/private/location_on.svg").default,
    help: require("../../assets/images/private/help.svg").default,
    assignment_add: require("../../assets/images/private/assignment_add.svg").default,
    cancel: require("../../assets/images/private/cancel.svg").default,
    filter_alt: require("../../assets/images/private/filter_alt.svg").default,    
}