import React, { useState } from 'react'

import images from '../images'
import { Link, useNavigate } from 'react-router-dom'
export default function Call() {
    const navigate = useNavigate();
    const [phoneNumber, setphoneNumber] = useState('')
    const [step, setStep] = useState("step1")
    const [error, setError] = useState(false);

    const updateStep = (data) => {
        console.log("data",data)
        setStep(data)
    }
    const updatePhoneNumber = (e) => {
        setphoneNumber(e.target.value)
    }
    const onCallMe = () => {
        if (phoneNumber.trim() === '') {
            setError(true);
          } else {
            updateStep("step2");
          } 
    }
    console.log(step)

    const talkTochatBox = () => {
        updateStep("step1")
        navigate("/support")
    }
    return (
        <>  <div className="forgotPassword ">

            <div className='customCard customCard--large'>
                <div className='customCard__heading  '>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <h6 className='font20 sbold headingWithImg'>
                                <Link to="/" className='me-2' ><img src={images.backArrow} width="15" /></Link>Customer Support</h6>
                        </div>
                        <div>
                            <Link to='/support' className='p-1'><img src={images.chat} width="18" /></Link>
                        </div>
                    </div>
                    <hr />
                </div>

                {step === "step1" ?
                    <div>
                        <p className='mb-20'>Have a call with our Customer Care. Enter your Phone number and we’ll call you in a minute</p>
                        <div className='mb-20'>
                            <input type="number" value={phoneNumber} onChange={(e) => updatePhoneNumber(e)} className='formField' placeholder='Enter your Phone number' />
                            {error && phoneNumber == "" ?
                                <div className='error d-flex'>
                                    <img className='error__img' src={images.warning} width="18" />
                                    <div className='error__cntnt'> Please Enter Phone Number</div>
                                </div> : null
                            }
                        </div>
                        <button type='button' className='common_btn' onClick={()=>onCallMe()} >Call Me</button>
                    </div> : null}
                {step === "step2" ?
                    <div className='callLogBox'>
                        <img className='callLogBox__icon' src={images.callLog} />
                        <h6 className='font-20'>Call Listed</h6>
                        <p>Your Number <strong>{phoneNumber}</strong> has been listed in the Support Queue, Please wait few moments to expect a call</p>
                        <div className='callLogBox__btn'>
                            <button className='common_btn' onClick={()=>updateStep("step3")}  >Done</button>
                            <button className='common_btn' onClick={()=>updateStep("step1")} >Edit Number</button>
                            <button className='common_btn common_btn--white' onClick={()=>talkTochatBox()}>Cancel</button>
                        </div>
                    </div> : null}
                {step === "step3" ?
                    <div className='callLogBox'>
                        <img className='callLogBox__icon' src={images.checkCircle} />
                        <h6 className='font-20'>Call done</h6>
                        <p>Hope we could have solved your problem. If No, please watch our tutorials for every steps and more help</p>
                        <div className='callLogBox__btn'>
                            <Link to="/" className='common_btn' >
                                <svg className='me-2' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.02087 15.6668C8.73893 15.6668 8.49831 15.5676 8.299 15.3691C8.09969 15.1707 8.00004 14.9311 8.00004 14.6504C8.00004 14.3697 8.09969 14.1286 8.299 13.9272C8.49831 13.7259 8.73893 13.6252 9.02087 13.6252H13.625V2.37516H9.02087C8.73893 2.37516 8.49831 2.27593 8.299 2.07748C8.09969 1.879 8.00004 1.63942 8.00004 1.35873C8.00004 1.07802 8.09969 0.836968 8.299 0.635579C8.49831 0.434191 8.73893 0.333496 9.02087 0.333496H13.625C14.1903 0.333496 14.6719 0.532455 15.0698 0.930372C15.4677 1.32829 15.6667 1.80989 15.6667 2.37516V13.6252C15.6667 14.1904 15.4677 14.672 15.0698 15.07C14.6719 15.4679 14.1903 15.6668 13.625 15.6668H9.02087ZM6.14587 9.00016H1.35344C1.07171 9.00016 0.83127 8.90093 0.632103 8.70248C0.43295 8.504 0.333374 8.26442 0.333374 7.98373C0.333374 7.70302 0.43295 7.46197 0.632103 7.26058C0.83127 7.05919 1.07171 6.9585 1.35344 6.9585H6.14587L5.04171 5.85433C4.81949 5.63211 4.70837 5.37814 4.70837 5.09243C4.70837 4.80671 4.81949 4.56018 5.04171 4.35285C5.26393 4.1455 5.51046 4.0453 5.78129 4.05225C6.05212 4.05919 6.29171 4.16683 6.50004 4.37516L9.41671 7.29183C9.62504 7.49511 9.72921 7.72995 9.72921 7.99637C9.72921 8.26279 9.62504 8.50016 9.41671 8.7085L6.52087 11.6043C6.31254 11.8127 6.06949 11.9168 5.79171 11.9168C5.51393 11.9168 5.26755 11.8057 5.05258 11.5835C4.837 11.3752 4.72574 11.1321 4.71879 10.8543C4.71185 10.5766 4.81949 10.3266 5.04171 10.1043L6.14587 9.00016Z" fill="white" />
                                </svg>

                                Login Page</Link>
                            <Link to="/" className='common_btn' >
                                <svg className='me-2' width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.416687 10.4323V1.56736C0.416687 1.26505 0.52036 1.0185 0.727708 0.827691C0.935041 0.6369 1.17694 0.541504 1.4534 0.541504C1.53978 0.541504 1.62818 0.555393 1.71858 0.583171C1.80899 0.610948 1.9006 0.658663 1.99342 0.726316L8.85419 5.14567C9.01391 5.24738 9.13717 5.3745 9.22398 5.52705C9.31078 5.6796 9.35419 5.83941 9.35419 6.00648C9.35419 6.17355 9.30905 6.33227 9.21877 6.48263C9.12849 6.63299 9.00696 6.75678 8.85419 6.854L1.99312 11.276C1.90031 11.3436 1.80619 11.3909 1.71077 11.4178C1.61534 11.4447 1.52426 11.4582 1.43752 11.4582C1.16808 11.4582 0.930576 11.3628 0.72502 11.172C0.519465 10.9812 0.416687 10.7346 0.416687 10.4323Z" fill="white" />
                                </svg>

                                Tutorials</Link>
                            <button className='common_btn' onClick={()=>talkTochatBox()}>
                                <svg className='me-2' width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 16L1.7 18.3C1.38333 18.6167 1.02083 18.6875 0.6125 18.5125C0.204167 18.3375 0 18.025 0 17.575V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H4ZM5 12H11C11.2833 12 11.5208 11.9042 11.7125 11.7125C11.9042 11.5208 12 11.2833 12 11C12 10.7167 11.9042 10.4792 11.7125 10.2875C11.5208 10.0958 11.2833 10 11 10H5C4.71667 10 4.47917 10.0958 4.2875 10.2875C4.09583 10.4792 4 10.7167 4 11C4 11.2833 4.09583 11.5208 4.2875 11.7125C4.47917 11.9042 4.71667 12 5 12ZM5 9H15C15.2833 9 15.5208 8.90417 15.7125 8.7125C15.9042 8.52083 16 8.28333 16 8C16 7.71667 15.9042 7.47917 15.7125 7.2875C15.5208 7.09583 15.2833 7 15 7H5C4.71667 7 4.47917 7.09583 4.2875 7.2875C4.09583 7.47917 4 7.71667 4 8C4 8.28333 4.09583 8.52083 4.2875 8.7125C4.47917 8.90417 4.71667 9 5 9ZM5 6H15C15.2833 6 15.5208 5.90417 15.7125 5.7125C15.9042 5.52083 16 5.28333 16 5C16 4.71667 15.9042 4.47917 15.7125 4.2875C15.5208 4.09583 15.2833 4 15 4H5C4.71667 4 4.47917 4.09583 4.2875 4.2875C4.09583 4.47917 4 4.71667 4 5C4 5.28333 4.09583 5.52083 4.2875 5.7125C4.47917 5.90417 4.71667 6 5 6Z" fill="#fff" />
                                </svg>
                                Talk to chatbot</button>
                        </div>
                    </div> : null}

            </div>
        </div>


        </>
    )
}
