import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import "../private.css";
import "./Home.css";
import images from '../images';
import Select from 'react-select'
import PortfolioLoss from './PortfolioLoss';
import InsurerWisePremium from './InsurerWisePremium';

import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { NavLink } from 'react-bootstrap';
import PopupAside from '../common/PopupAside';
import ActivePolicies from './ActivePolicies';
import InsurerWisePremiumPopup from './InsurerWisePremiumPopup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';


const CY = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
]

export const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!', eventKey),
    );

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

export const insights = [
    {
        title: "Active Policies", total: "25,000", icon: images?.verified_user, data: [
            { title: "Fire", total: "5,000", icon: images.local_fire_department },
            { title: "Liability", total: "5,000", icon: images.real_estate_agent },
            { title: "Terrorism", total: "2,000", icon: images.domino_mask },
            { title: "Marine", total: "2,000", icon: images.directions_boat },
            { title: "Health", total: "10,000", icon: images.ecg_heart },
            { title: "Motor", total: "1,000", icon: images.directions_car },
        ]
    },
    {
        title: "Portfolio premium ", total: "₹ 2,00,00,000", icon: images?.contract, data: [
            { title: "Fire", total: "₹ 258,000", icon: images.local_fire_department },
            { title: "Liability", total: "₹ 256,000", icon: images.real_estate_agent },
            { title: "Terrorism", total: "₹ 128,000", icon: images.domino_mask },
            { title: "Marine", total: "₹ 500,000", icon: images.directions_boat },
            { title: "Health", total: "₹ 800,000", icon: images.ecg_heart },
            { title: "Motor", total: "₹ 200,000", icon: images.directions_car },
        ]
    },
    {
        title: "Outstanding claims", total: "14", icon: images?.assignment_late, data: [
            { title: "Fire", total: "02", icon: images.local_fire_department },
            { title: "Liability", total: "04", icon: images.real_estate_agent },
            { title: "Terrorism", total: "05", icon: images.domino_mask },
            { title: "Marine", total: "00", icon: images.directions_boat },
            { title: "Health", total: "00", icon: images.ecg_heart },
            { title: "Motor", total: "03", icon: images.directions_car },
        ]
    },
]
export const bifurcation = [
    {
        title: "Master Policies", total: "20,000", icon: images?.groups_2, data: [
            { title: "Fire", total: "5,000", icon: images.local_fire_department },
            { title: "Liability", total: "5,000", icon: images.real_estate_agent },
            { title: "Terrorism", total: "2,000", icon: images.domino_mask },
            { title: "Marine", total: "2,000", icon: images.directions_boat },
            { title: "Health", total: "10,000", icon: images.ecg_heart },
            { title: "Motor", total: "1,000", icon: images.directions_car },
            { title: "Property", total: "1,000", icon: images.corporate_fare },
        ]
    },
    {
        title: "Local Policies", total: "5,050", icon: images?.person, data: [
            { title: "Fire", total: "50", icon: images.local_fire_department },
            { title: "Liability", total: "600", icon: images.real_estate_agent },
            { title: "Terrorism", total: "00", icon: images.domino_mask },
            { title: "Marine", total: "2,500", icon: images.directions_boat },
            { title: "Health", total: "500", icon: images.ecg_heart },
            { title: "Motor", total: "2,000", icon: images.directions_car },
            { title: "Property", total: "2,000", icon: images.corporate_fare },
        ]
    },
]
export const claimData = [
    {
        title: "Claims Reported for last 12 months", total: 12, icon: images?.beenhere, amount: "₹ 80,000", data: [
            { title: "Fire", total: "₹ 20,000", icon: images.local_fire_department },
            { title: "Liability", total: "₹ 50,000", icon: images.real_estate_agent },
            { title: "Terrorism", total: "₹ 10,000", icon: images.domino_mask },
            { title: "Marine", total: "₹ 10,000", icon: images.directions_boat },
        ]
    },
    {
        title: "Claims Settled for last 12 months", total: "01", icon: images?.assignment_turned_in, amount: "₹ 154,000", data: [
            { title: "Liability", total: "₹ 154,000", icon: images.real_estate_agent },
        ]
    },
]

export const premium = [
    { name: "National Insurance", short_name: "National", color: "#014F65", percentage: 40 },
    { name: "The New India Assurance Co. Ltd.", short_name: "Term Life", color: "#01C2F3", percentage: 20 },
    { name: "HDFC ERGO General Insurance Co Ltd.", short_name: "Property", color: "#26A18F", percentage: 30 },
    { name: "Other", short_name: "Motor", color: "#7BE582", percentage: 10 },
]
export const premium2 = [
    { name: "National Insurance", percentage: 20, color: "#014F65" },
    { name: "The New India Assurance Co. Ltd.", percentage: 40, color: "#01C2F3" },
    { name: "HDFC ERGO General Insurance Co Ltd.", percentage: 30, color: "#26A18F" },
    { name: "Reliance General Insurance", percentage: 10, color: "#7BE582" },
    { name: "Bharti AXA Life Insurance", percentage: 35, color: "#26A18F" },
    { name: "Care Health Insurance", percentage: 50, color: "#014F65" },
    { name: "SBI General Insurance", percentage: 25, color: "#7BE582" },
    { name: "IFFCO Tokio", percentage: 30, color: "#01C2F3" },
    { name: "ICICI Lombard", percentage: 20, color: "#26A18F" },
    { name: "Liberty General Insurance", percentage: 45, color: "#014F65" },
    { name: "Kotak Mahindra General Insurance", percentage: 50, color: "#01C2F3" },
    { name: "Future Generali India insurance", percentage: 10, color: "#26A18F" },
]
export const city = [
    { name: "Mumbai", value: "51,005" },
    { name: "Pune", value: "12,524" },
    { name: "Ratnagiri", value: "237" },
    { name: "Bangalore", value: "25,015" },
    { name: "Delhi", value: "25,015" },
    { name: "Punjab", value: "51,005" },
    { name: "Rajasthan", value: "12,524" },
    { name: "Himachal", value: "237" },
    { name: "Agra", value: "25,015" },
    { name: "Chandigarh", value: "25,015" },
    { name: "Goa", value: "51,005" },
]
export const claimRegister = [
    {
        title: 'Claims reported for last 12 months', value: 12, incurred: "₹ 130,094", paid: "₹ 79,580", total: "25,00", icon: images.beenhere, data: [
            { title: "Fire", incured: "5,000", paid: "5,000", count: "5,000", icon: images.local_fire_department },
            { title: "Liability", incured: "5,000", paid: "5,000", count: "5,000", total: "04", icon: images.real_estate_agent },
            { title: "Terrorism", incured: "5,000", paid: "5,000", count: "5,000", total: "05", icon: images.domino_mask },
            { title: "Marine", incured: "5,000", paid: "5,000", count: "5,000", total: "00", icon: images.directions_boat },
            { title: "Health", incured: "5,000", paid: "5,000", count: "5,000", total: "00", icon: images.ecg_heart },
            { title: "Motor", incured: "5,000", paid: "5,000", count: "5,000", total: "03", icon: images.directions_car },
        ]
    },
    {
        title: 'Outstanding Claims', value: 14, incurred: "₹ 1,224,894", paid: "₹ 1,064,480", total: "14", icon: images.assignment_late, data: [
            { title: "Fire", incured: "5,000", paid: "5,000", count: "5,000", icon: images.local_fire_department },
            { title: "Liability", incured: "5,000", paid: "5,000", count: "5,000", total: "04", icon: images.real_estate_agent },
            { title: "Terrorism", incured: "5,000", paid: "5,000", count: "5,000", total: "05", icon: images.domino_mask },
            { title: "Marine", incured: "5,000", paid: "5,000", count: "5,000", total: "00", icon: images.directions_boat },
            { title: "Health", incured: "5,000", paid: "5,000", count: "5,000", total: "00", icon: images.ecg_heart },
            { title: "Motor", incured: "5,000", paid: "5,000", count: "5,000", total: "03", icon: images.directions_car },
        ]
    },
    {
        title: 'Settled Claims', value: 1, incurred: "₹ 78,000", paid: "₹ 78,000", total: "01", icon: images.assignment_turned_in, data: [
            { title: "Fire", incured: "5,000", paid: "5,000", count: "5,000", icon: images.local_fire_department },
            { title: "Liability", incured: "5,000", paid: "5,000", count: "5,000", total: "04", icon: images.real_estate_agent },
            { title: "Terrorism", incured: "5,000", paid: "5,000", count: "5,000", total: "05", icon: images.domino_mask },
            { title: "Marine", incured: "5,000", paid: "5,000", count: "5,000", total: "00", icon: images.directions_boat },
            { title: "Health", incured: "5,000", paid: "5,000", count: "5,000", total: "00", icon: images.ecg_heart },
            { title: "Motor", incured: "5,000", paid: "5,000", count: "5,000", total: "03", icon: images.directions_car },
        ]
    },
]

export const graphData = [
    { title: "Fire", percent: 20, total: "130000000", value: "13,00,00,000", icon: images.local_fire_department },
    { title: "Liability", percent: 70, total: "100000000", value: "10,00,00,000", icon: images.real_estate_agent },
    { title: "Terrorism", percent: 80, total: "150000000", value: "15,00,00000", icon: images.domino_mask },
    { title: "Marine", percent: 55, total: "125000000", value: "12,50,00,000", icon: images.directions_boat },
    { title: "Health", percent: 80, total: "60000000", value: "6,00,00,000", icon: images.ecg_heart },
    { title: "Motor", percent: 10, total: "130000000", value: "13,00,00,000", icon: images.directions_car },
]
export const graphData2 = [
    { name: "Fire", percentage: 20, color: "#01C2F3" },
    { name: "Liability", percentage: 70, color: "#26A18F" },
    { name: "Terrorism", percentage: 80, color: "#7BE582" },
    { name: "Marine", percentage: 55, color: "#26A18F" },
    { name: "Health", percentage: 80, color: "#014F65" },
    { name: "Motor", percentage: 10, color: "#7BE582" },
    { name: "Property", percentage: 70, color: "#26A18F" },
    { name: "Travel", percentage: 80, color: "#7BE582" },
    { name: "Senior Citizen", percentage: 55, color: "#26A18F" },
    { name: "Travel", percentage: 80, color: "#014F65" },
    { name: "Term Life", percentage: 10, color: "#7BE582" },
    { name: "Family Floater", percentage: 70, color: "#26A18F" },
    { name: "Engineering", percentage: 10, color: "#7BE582" },
]
export const tracker = [
    { name: "Policies overdue for renewal", value: 17, icon: images.gpp_maybe, data: graphData },
    { name: "Expiring policies for current month", value: 23, icon: images.event, data: graphData },
    { name: "Renewal policies of next 3 months", value: 54, icon: images.calendar_clock, data: graphData },
]

export const monthData = [
    { month: "Apr", value: 45, amount: "₹ 258,000" },
    { month: "May", value: "50", amount: "₹ 258,000" },
    { month: "Jun", value: "12", amount: "₹ 258,000" },
    { month: "Jul", value: "10", amount: "₹ 258,000" },
    { month: "Aug", value: "10", amount: "₹ 258,000" },
    { month: "Sep", value: "15", amount: "₹ 258,000" },
    { month: "Oct", value: "16", amount: "₹ 258,000" },
    { month: "Nov", value: "08", amount: "₹ 258,000" },
    { month: "Dec", value: "06", amount: "₹ 258,000" },
    { month: "Jan", value: "14", amount: "₹ 258,000" },
    { month: "Feb", value: "15", amount: "₹ 258,000" },
    { month: "Mar", value: "12", amount: "₹ 258,000" },
]

export const worldwide = [
    { category: "Assets / Property / Location", unit: 0, value: "₹ 17,444,520,181" },
    { category: "Liability", unit: 0, value: "₹ 10,000,000" },
    { category: "Marine / Transit", unit: 0, value: "₹ 569,000" },
    { category: "Employee Benefits", unit: 0, value: "₹ 982,621,489" },
]

export const view = [
    {
        title: "Group View", data: [
            { icon: images.store, value: 3, title: "Number of Subsidiary companies" },
            { icon: images.verified_user, value: 4, title: "Total number of Active Policies" },
            { icon: images.assignment_late, value: 0, title: "Outstanding Claims" }
        ]
    },
    {
        title: "Master Programs (Global view)", data: [
            { icon: images.public, value: 3, title: "Countries Covered" },
            { icon: images.category, value: 4, title: "Total product types" },
            { icon: images.security, value: 0, title: "Active Master policies" },
            { icon: images.shield, value: 0, title: "Active Master policies" }
        ]
    },
]
export const zone = [
    { label: "All", value: "all" },
    { label: "Zone 1", value: "Zone 1" },
    { label: "Zone 2", value: "Zone 2" },
    { label: "Zone 3", value: "Zone 3" },
]
export const branch = [
    { label: "All", value: "all" },
    { label: "Branch 1", value: "Branch 1" },
    { label: "Branch 2", value: "Branch 2" },
    { label: "Branch 3", value: "Branch 3" },
]
export const manager = [
    { label: "All", value: "all" },
    { label: "Manager 1", value: "Manager 1" },
    { label: "Manager 2", value: "Manager 2" },
    { label: "Manager 3", value: "Manager 3" },
]
export const activePolicies = [
    { rider: "Omar Korsgaard", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Fire", policy: "169546126946" },
    { rider: "Aspen Passaquindici Arcand", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Motor", policy: "468413546947" },
    { rider: "Cristofer Carder", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Marine", policy: "685463549549" },
    { rider: "Charlie Saris", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Terrorism", policy: "6512635463515" },
    { rider: "Gretchen Mango", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Fire", policy: "169546126946" },
    { rider: "Omar Korsgaard", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Fire", policy: "169546126946" },
    { rider: "Aspen Passaquindici Arcand", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Motor", policy: "468413546947" },
    { rider: "Cristofer Carder", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Marine", policy: "685463549549" },
    { rider: "Charlie Saris", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Terrorism", policy: "6512635463515" },
    { rider: "Gretchen Mango", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Fire", policy: "169546126946" },
    { rider: "Omar Korsgaard", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Fire", policy: "169546126946" },
    { rider: "Aspen Passaquindici Arcand", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Motor", policy: "468413546947" },
    { rider: "Cristofer Carder", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Marine", policy: "685463549549" },
    { rider: "Charlie Saris", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Terrorism", policy: "6512635463515" },
    { rider: "Gretchen Mango", number: 2, premium: "₹ 5,000", date: "12 Jan, 2024 - 11 Jan, 2024", type: "Fire", policy: "169546126946" },
]
export default function AdminHome() {
    const [title, setTitle] = useState("")
    const [icon, seticon] = useState("")
    const [show, setShow] = useState(false)
    const [showFilters, setshowFilters] = useState(false)
    const [activePolicy, setactivePolicy] = useState(false)
    const [portfolioLoss, setportfolioLoss] = useState(false)
    const [insuranceWisePopup, setinsuranceWisePopup] = useState(false)
    const handleClose = () => {
        setShow(false)
        setactivePolicy(false)
        setportfolioLoss(false)
        setinsuranceWisePopup(false)
    }
    const showPolicyPopup = (text, icon) => {
        setShow(true)
        setactivePolicy(true)
        setTitle(text)
        seticon(icon)
    }
    const showPortfolioPopup = (text, icon) => {
        setShow(true)
        setportfolioLoss(true)
        setTitle(text)
        seticon(icon)
    }
    const showInsuranceWisePopup = (text, icon) => {
        setShow(true)
        setinsuranceWisePopup(true)
        setTitle(text)
        seticon(icon)
    }
    const clickFilters = () => {
        setshowFilters(!showFilters)
    }

    return (
        <div className='dashboard_main'>
            <div className='white-bg container_space'>
                <div className='dashboard_header position-relative flex-row'>
                    <h5 className='font16 sbold mb-0'>Insights</h5>
                    <button onClick={clickFilters} className='d-block d-md-none'>
                        <img src={images.filter_alt} alt='' />
                    </button>
                    <div className={`filter_outer ${showFilters ? "show" : ""}`}>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center mb-md-0 mb-2'>
                                <label className='font12 med me-3 ms-4' style={{ whiteSpace: "nowrap" }}>Zone</label>
                                <Select
                                    options={zone}
                                    defaultValue={zone[0]}
                                    className='w-100'
                                    classNamePrefix="select"
                                    menuPlacement='auto'
                                    isSearchable={false}
                                    placeholder=""
                                />
                            </div>
                            <div className='d-flex align-items-center mb-md-0 mb-2'>
                                <label className='font12 med me-3 ms-4' style={{ whiteSpace: "nowrap" }}>Branch</label>
                                <Select
                                    options={branch}
                                    defaultValue={branch[0]}
                                    className='w-100'
                                    classNamePrefix="select"
                                    menuPlacement='auto'
                                    isSearchable={false}
                                    placeholder=""
                                />
                            </div>
                            <div className='d-flex align-items-center'>
                                <label className='font12 med me-3 ms-4' style={{ whiteSpace: "nowrap" }}>Account Manager</label>
                                <Select
                                    options={manager}
                                    defaultValue={manager[0]}
                                    className='w-100'
                                    classNamePrefix="select"
                                    menuPlacement='auto'
                                    isSearchable={false}
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row gy-4 mb-4'>
                    {insights.map((opt, i) => {
                        return (
                            <div className='col-xl-4 col-md-6' key={i}>
                                <div className='custom_card p-3'>
                                    <Accordion className='custom_accordion'>
                                        <Accordion.Item eventKey={i}>
                                            {/* <Accordion.Header> */}

                                            <button className='accordion-button collapsed' type='button'>
                                                <button className='font14 med mb-0 text-start' style={{ flexGrow: 1 }} onClick={() => showPolicyPopup(opt.title, opt.icon)}>
                                                    <img src={opt.icon} alt="" className='me-3' />
                                                    {opt.title}
                                                </button>
                                                <h5 className='font14 sbold text-black mb-0'>
                                                    {opt.total}
                                                    <CustomToggle eventKey={i}><img src={images.navigate_next} alt="" className='ms-2 arrow_icon' style={{ verticalAlign: "inherit" }} /></CustomToggle>
                                                </h5>
                                            </button>
                                            {/* </Accordion.Header> */}
                                            <Accordion.Body>
                                                <ul className='insights_list'>
                                                    {opt.data.map((data, ind) => {
                                                        return (
                                                            <li key={ind}>
                                                                <h6 className='font12 med mb-0'>
                                                                    <img src={data.icon} alt="" className='me-3' />
                                                                    {data.title}
                                                                </h6>
                                                                <span className='custom_badge'>
                                                                    {data.total}
                                                                </span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='row gy-3 mb-4'>
                    {claimData.map((opt, i) => {
                        return (
                            <div className='col-xl-6' key={i}>
                                <div className='custom_card border_card p-3 claim_reported h-100'>
                                    <div className='claim_reported_header'>
                                        <h5 className='font20 sbold text-black'>
                                            <img src={opt.icon} alt="" className='me-3' />
                                            {opt.total}
                                        </h5>
                                        <p className='font14 med mb-0'>
                                            {opt.title}
                                        </p>
                                    </div>
                                    <div className='claim_reported_table h-100'>
                                        <ul className='insights_list'>
                                            {opt.data.map((data, ind) => {
                                                return (
                                                    <li key={ind}>
                                                        <h6 className='font12 med mb-0'>
                                                            <img src={data.icon} alt="" className='me-3' />
                                                            {data.title}
                                                        </h6>
                                                        <span className='custom_badge'>
                                                            {data.total}
                                                        </span>
                                                    </li>
                                                )
                                            })}
                                            <li>
                                                <h6 className='font12 med mb-0'>
                                                    Premium Amount
                                                </h6>
                                                <h6 className='font12 sbold mb-0'>
                                                    {opt.amount}
                                                </h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='container_space'>
                {/* <div className='portfolio_loss_outer'> */}
                <div className='row gy-4'>
                    <div className='col-xl-7'>
                        <div className='custom_card p-4'>
                            <div className='portfolio_loss_block border-0 p-0'>
                                <div className='dashboard_header'>
                                    <h5 className='font16 sbold mb-0'>Portfolio Loss ratio (Product Wise)</h5>
                                    <div className='d-flex'>
                                        <h6 className='font12 med mb-0'><span className='portfolio_legend me-2' style={{ backgroundColor: "#00B33C" }}></span> Premium</h6>
                                        <h6 className='font12 med mb-0 ms-4'><span className='portfolio_legend me-2' style={{ backgroundColor: "#F30808" }}></span> Loss%</h6>
                                    </div>
                                </div>
                                <PortfolioLoss graphData={graphData} list={false} />
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-5'>
                        <div className='custom_card p-4 h-100'>
                            <div className='portfolio_loss_block border-0 p-0'>
                                <div className='dashboard_header flex-row'>
                                    <h5 className='font16 sbold mb-0'>Insurer Wise Premium</h5>
                                    <button onClick={() => showInsuranceWisePopup("Insurer Wise Premium", images.shield, "insurer")} className='font12' style={{ color: "#6FC72E", whiteSpace: "nowrap" }}>View all <svg width="6" height="10" className='ms-1' viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.82984 4.97916L0.414774 1.56407C0.243829 1.39314 0.157024 1.20298 0.154357 0.993572C0.151677 0.784183 0.238482 0.59135 0.414774 0.415072C0.591052 0.23878 0.782551 0.150635 0.989274 0.150635C1.196 0.150635 1.38985 0.241128 1.57084 0.422114L5.47563 4.32693C5.56752 4.42436 5.63643 4.52537 5.68236 4.62997C5.7283 4.73455 5.75127 4.85234 5.75127 4.98332C5.75127 5.11431 5.7283 5.23209 5.68236 5.33668C5.63643 5.44127 5.56752 5.53951 5.47563 5.63138L1.57084 9.5362C1.3952 9.71184 1.20615 9.79752 1.00369 9.79324C0.801245 9.78897 0.611885 9.6987 0.435607 9.52241C0.259315 9.34613 0.171169 9.15463 0.171169 8.94791C0.171169 8.74118 0.259315 8.54968 0.435607 8.37341L3.82984 4.97916Z" fill="#6FC72E" />
                                    </svg>

                                    </button>
                                </div>
                                <InsurerWisePremium data={premium} graphcol={'col-sm-6'} col={'col-sm-6'} />
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-7'>
                        <div className='mt-0 mt-xl-2 portfolio_slider_list_outer'>
                            <div className='portfolio_slider_list'>
                                {graphData.map((opt, i) => {
                                    return (
                                        <div onClick={() => showPortfolioPopup(opt.title, opt.icon)} style={{ cursor: "pointer" }} className='custom_card p-3 d-flex' key={i}>
                                            <div className='portfolio_slider_icon'>
                                                <img src={opt.icon} alt='' />
                                            </div>
                                            <div className='portfolio_slider_info'>
                                                <h5 className='font14 med mb-1' style={{ color: "#515151" }}>{opt.title}</h5>
                                                <p className='font12 text-black med mb-1'>{opt.value}</p>
                                                <p className='font12 med' style={{ color: "#F30808" }}>{opt.percent}%</p>
                                            </div>
                                        </div>
                                    )
                                })}
                                {graphData.map((opt, i) => {
                                    return (
                                        <div onClick={() => showPortfolioPopup(opt.title, opt.icon)} style={{ cursor: "pointer" }} className='custom_card p-3 d-flex' key={i}>
                                            <div className='portfolio_slider_icon'>
                                                <img src={opt.icon} alt='' />
                                            </div>
                                            <div className='portfolio_slider_info'>
                                                <h5 className='font14 med mb-1' style={{ color: "#515151" }}>{opt.title}</h5>
                                                <p className='font12 text-black med mb-1'>{opt.value}</p>
                                                <p className='font12 med' style={{ color: "#F30808" }}>{opt.percent}%</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-5'>
                        <div className='custom_card p-3 mt-0 mt-xl-4'>
                            <div className='' style={{ borderBottomWidth: 0 }}>
                                <div className='row gy-4'>
                                    <div className='col-sm-6'>
                                        <div className='portfolio_loss_block p-0 h-100'>
                                            <p className='mb-2'>Total Premium amount</p>
                                            <p className='med text-black mb-0'>₹ 177,981,830</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='portfolio_loss_block border-0 p-0 h-100'>
                                            <p className='mb-2'>Number of Insurance Companies</p>
                                            <p className='med text-black mb-0'>10</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='custom_card p-4 mt-4'>
                    <div className='portfolio_loss_block border-0 p-0'>
                        <div className='dashboard_header'>
                            <h5 className='font16 sbold mb-0'>Portfolio Loss ratio (Product Wise)</h5>
                            <div className='d-flex'>
                                <h6 className='font12 med mb-0'><span className='portfolio_legend me-2' style={{ backgroundColor: "#00B33C" }}></span> Premium</h6>
                                <h6 className='font12 med mb-0 ms-4'><span className='portfolio_legend me-2' style={{ backgroundColor: "#F30808" }}></span> Loss%</h6>
                            </div>
                        </div>
                        <PortfolioLoss graphData={graphData} list={false} />
                    </div>
                </div>
                <div className='mt-2 portfolio_slider_list_outer'>
                    <div className='portfolio_slider_list'>
                        {graphData.map((opt, i) => {
                            return (
                                <div className='custom_card p-3 d-flex' key={i} onClick={() => showPortfolioPopup(opt.title, opt.icon)} style={{ cursor: "pointer" }}>
                                    <div className='portfolio_slider_icon'>
                                        <img src={opt.icon} alt='' />
                                    </div>
                                    <div className='portfolio_slider_info'>
                                        <h5 className='font14 med mb-1' style={{ color: "#515151" }}>{opt.title}</h5>
                                        <p className='font12 text-black med mb-1'>{opt.value}</p>
                                        <p className='font12 med' style={{ color: "#F30808" }}>{opt.percent}%</p>
                                    </div>
                                </div>
                            )
                        })}
                        {graphData.map((opt, i) => {
                            return (
                                <div className='custom_card p-3 d-flex' key={i} onClick={() => showPortfolioPopup(opt.title, opt.icon)} style={{ cursor: "pointer" }}>
                                    <div className='portfolio_slider_icon'>
                                        <img src={opt.icon} alt='' />
                                    </div>
                                    <div className='portfolio_slider_info'>
                                        <h5 className='font14 med mb-1' style={{ color: "#515151" }}>{opt.title}</h5>
                                        <p className='font12 text-black med mb-1'>{opt.value}</p>
                                        <p className='font12 med' style={{ color: "#F30808" }}>{opt.percent}%</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <div className='claim_register'>
                    <div className='dashboard_header'>
                        <h5 className='font16 sbold mb-0'>Claim Register</h5>
                    </div>
                    <div className='row mt-4'>
                        {claimRegister.map((opt, i) => {
                            return (
                                <div className='col-md-4 d-flex'>
                                    <div className='portfolio_slider_icon'>
                                        <img src={opt.icon} alt='' />
                                    </div>
                                    <div className='portfolio_slider_info'>
                                        <h5 className='font12 mb-1' style={{ color: "#515151" }}>{opt.title}</h5>
                                        <p className='font12 text-black med mb-3'>{opt.value}</p>
                                        <h5 className='font12 mb-1' style={{ color: "#515151" }}>Incurred</h5>
                                        <p className='font12 text-black med mb-3'>{opt.incurred}</p>
                                        <h5 className='font12 mb-1' style={{ color: "#515151" }}>Paid</h5>
                                        <p className='font12 text-black med mb-3'>{opt.paid}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div> */}

            </div>
            <div className='white-bg container_space'>
                <div className='dashboard_header'>
                    <h5 className='font16 sbold mb-0'>Claim Register</h5>
                </div>
                <div className='row gy-3 mb-4'>
                    {claimRegister.map((opt, i) => {
                        return (
                            <div className='col-xl-4 col-md-6' key={i}>
                                <div className='custom_card p-3 h-100'>
                                    <Accordion className='custom_accordion'>
                                        <Accordion.Item eventKey={i}>
                                            {/* <Accordion.Header> */}

                                            <button className='accordion-button collapsed' type='button' >
                                                <button className='font14 med mb-0 w-50'>
                                                    <img src={opt.icon} alt="" className='me-3' />
                                                    {opt.title}
                                                </button>
                                                <h5 className='font14 sbold text-black mb-0'>
                                                    {opt.total}
                                                    <CustomToggle eventKey={i}><img src={images.navigate_next} alt="" className='ms-2 arrow_icon' style={{ verticalAlign: "inherit" }} /></CustomToggle>
                                                </h5>
                                            </button>
                                            {/* </Accordion.Header> */}
                                            <Accordion.Body>
                                                <div className="exposure_table">
                                                    <table className=''>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ opacity: 0.8 }} className='reg font10'>Category</th>
                                                                <th style={{ opacity: 0.8 }} className='reg font10'>Incurred</th>
                                                                <th style={{ opacity: 0.8 }} className='reg font10'>Paid</th>
                                                                <th style={{ opacity: 0.8 }} className='reg font10'>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {opt.data.map((data, ind) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td className=''>
                                                                            <h6 className='font12 med mb-0'>
                                                                                <img src={data.icon} alt="" className='me-3' />
                                                                                {data.title}
                                                                            </h6>
                                                                        </td>
                                                                        <td className='font12 med' style={{ color: "#F30808" }}>
                                                                            {data.incured}
                                                                        </td>
                                                                        <td className='font12 med' style={{ color: "#0AD017" }}>
                                                                            {data.paid}
                                                                        </td>
                                                                        <td className='font14 sbold text-black'>
                                                                            <span className='custom_badge text-black font12'>{data.count} </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr>
                                                                <td className='border-0 '>
                                                                    <h6 className='font12 med mb-0'>
                                                                        Total
                                                                    </h6>
                                                                </td>
                                                                <td className='border-0 font12 med' style={{ color: "#F30808" }}>
                                                                    1,000
                                                                </td>
                                                                <td className='border-0 font12 med' style={{ color: "#0AD017" }}>
                                                                    1,000
                                                                </td>
                                                                <td className='border-0 font14 sbold text-black'>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='expose_outer'>
                    <div className='dashboard_header align-items-start'>
                        <div>
                            <h5 className='font16 sbold mb-2'>Renewal & Premium installment trackers</h5>
                        </div>
                        <div className='d-flex'>
                            <Select
                                options={CY}
                                defaultValue={CY[0]}
                                className='w-100'
                                classNamePrefix="select"
                                menuPlacement='auto'
                                isSearchable={false}
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className='row gy-3'>
                        <div className='col-xl-7'>
                            {tracker.map((opt, i) => {
                                return (
                                    <div key={i} className='custom_card p-3 claim_reported mb-3'>
                                        <div className='claim_reported_header'>
                                            <div style={{ minHeight: "60px" }}>
                                                <img src={opt.icon} alt="" className='me-3' />
                                            </div>
                                            <h5 className='font20 sbold text-black'>
                                                {opt.value}
                                            </h5>
                                            <p className='font14 med mb-0'>
                                                {opt.name}
                                            </p>
                                        </div>
                                        <div className='claim_reported_table'>
                                            <div className="exposure_table">
                                                <table className=''>
                                                    <thead>
                                                        <tr>
                                                            <th className='font10 sbold'>Category</th>
                                                            <th className='font10 sbold'>Count</th>
                                                            <th className='font10 sbold text-end'>Premium</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {opt.data.map((data, ind) => {
                                                            return (
                                                                <tr key={ind}>
                                                                    <td className='font12 med text-black'>
                                                                        <h6 className='font12 med mb-0'>
                                                                            <img src={data.icon} alt="" className='me-3' />
                                                                            {data.title}
                                                                        </h6>
                                                                    </td>
                                                                    <td className='font12 med text-black'>
                                                                        {data.percent}
                                                                    </td>
                                                                    <td className='font12 med text-black text-end'>
                                                                        <span className='custom_badge text-black font12'>{data.value} </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td>
                                                                <h6 className='font12 med mb-0'>
                                                                    Premium Amount
                                                                </h6>
                                                            </td>
                                                            <td>
                                                            </td>
                                                            <td className='text-end'>
                                                                <span className='custom_badge text-black font12'>
                                                                    ₹ 154,00,000
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='col-xl-5'>
                            <div className='custom_card p-3'>
                                <div className="exposure_table">
                                    <table className=''>
                                        <thead>
                                            <tr>
                                                <th className='font10 sbold'>Month</th>
                                                <th className='font10 sbold'>No of Policies</th>
                                                <th className='font10 sbold text-end'>Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {monthData.map((data, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td className='font12 med text-black'>
                                                            {data.month}
                                                        </td>
                                                        <td className='font12 med text-black'>
                                                            {data.value}
                                                        </td>
                                                        <td className='font12 med text-black text-end'>
                                                            <span className='custom_badge text-black font12'>{data.amount} </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td>
                                                    <h6 className='font12 med mb-0'>
                                                        Premium Amount
                                                    </h6>
                                                </td>
                                                <td>
                                                </td>
                                                <td className='text-end'>
                                                    <span className='custom_badge text-black font12'>
                                                        ₹ 154,00,000
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className='container_space'>
                <div className='expose_outer'>
                    <div className='dashboard_header align-items-start'>
                        <div>
                            <h5 className='font16 sbold mb-2'>Exposure</h5>
                        </div>
                        <div className='d-flex'>
                            <Select
                                options={CY}
                                defaultValue={CY[0]}
                                className='w-100'
                                classNamePrefix="select"
                                menuPlacement='auto'
                                isSearchable={false}
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='custom_card expose_map'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15249885.318783779!2d82.75252935!3d21.0680074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1716204050060!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0, verticalAlign: "middle" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='exposure_slider_outer'>
                                <div className='exposure_slider'>
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={2}
                                        navigation
                                        modules={[Navigation]}
                                        breakpoints={{
                                            1199: {
                                                slidesPerView: 5,
                                            },
                                            992: {
                                                slidesPerView: 3,
                                            },
                                            768: {
                                                slidesPerView: 5,
                                            },
                                            575: {
                                                slidesPerView: 4,
                                            },
                                            400: {
                                                slidesPerView: 3,
                                            }
                                        }}
                                    // onSlideChange={() => console.log('slide change')}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        {city.map((opt, i) => {
                                            return (
                                                <SwiperSlide key={i}>
                                                    <div className='custom_card' key={i}>
                                                        <h5 className='font14 text-black med mb-1'>{opt.name}</h5>
                                                        <p className='font12 med mb-1' style={{ color: "#515151" }}>{opt.value}</p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                                {/* <button className='exposure_slider_arrow'>
                                    <img src={images.navigate_next} alt="" className='' style={{ verticalAlign: "inherit" }} />
                                </button> */}
                            </div>
                            <hr />

                            <div className='worldwide_exposure'>
                                <div className='dashboard_header align-items-start'>
                                    <h5 className='font16 sbold mb-2'>Worldwide exposure</h5>
                                </div>
                                <div className="custom_card p-3 pb-5 h-100">
                                    <div className="exposure_table">
                                        <table className=''>
                                            <thead>
                                                <tr>
                                                    <th width="40%">Exposure category</th>
                                                    <th width="30%">Unit</th>
                                                    <th width="30%">Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {worldwide.map((opt, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className='font14 sbold text-black'>
                                                                {opt.category}
                                                            </td>
                                                            <td className='font14 sbold text-black'>
                                                                {opt.unit}
                                                            </td>
                                                            <td className='font14 sbold text-black'>
                                                                <span className='custom_badge text-black font12'>{opt.value} </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='white-bg container_space pt-4'>
                <div className='view_outer'>
                    {view.map((opt, i) => {
                        return (
                            <div className='view_inner' key={i}>
                                <div className='dashboard_header flex-row'>
                                    <h5 className='font16 sbold mb-0'>{opt.title}</h5>
                                    <NavLink to={""} className='font12'>Details <img src={images.navigate_next} className='ms-2' alt='' /></NavLink>
                                </div>
                                <div className='view_list mt-4'>
                                    {opt.data.map((data, ind) => {
                                        return (
                                            <div className='col' key={ind}>
                                                <div className='custom_card p-3 h-100'>
                                                    <div style={{ minHeight: "60px" }}>
                                                        <img src={data.icon} alt='' height={25} />
                                                    </div>
                                                    <h5 className='font18 sbold text-black'>{data.value}</h5>
                                                    <p className='font12 text-black'>{data.title}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {show ?
                <PopupAside position={"right"} size={"lg"} close={handleClose}>
                    {activePolicy &&
                        <div>
                            <div className='dashboard_header flex-row'>
                                <h5 className='font16 sbold mb-0'><img src={icon} className='me-2' alt='' /> {title}</h5>
                                <button onClick={handleClose}><img src={images.close} className='' alt='' /></button>
                            </div>
                            <ActivePolicies data={activePolicies} />
                        </div>
                    }
                    {portfolioLoss &&
                        <div>
                            <div className='dashboard_header flex-row'>
                                <h5 className='font16 sbold mb-0'>Portfolio Loss ratio (Product Wise)</h5>
                                <button onClick={handleClose}><img src={images.close} className='' alt='' /></button>
                            </div>
                            <div className='custom_card p-3 mb-3'>
                                <PortfolioLoss graphData={graphData} list={false} />
                            </div>
                            <div className="custom_card p-3 h-100">
                                <div className="exposure_table">
                                    <table className=''>
                                        <thead>
                                            <tr>
                                                <th width="40%">Exposure category</th>
                                                <th width="40%">Premium</th>
                                                <th width="20%">Loss ratio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {graphData.map((opt, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className='font14 sbold text-black'>
                                                            <h6 className='font12 med mb-0'>
                                                                <img src={opt.icon} alt="" className='me-3' />
                                                                {opt.title}
                                                            </h6>
                                                        </td>
                                                        <td className='font12 sbold text-black'>
                                                            ₹ {opt.value}
                                                        </td>
                                                        <td className='font14 sbold text-black'>
                                                            <p className='font12 med' style={{ color: "#F30808" }}>{opt.percent}%</p>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {insuranceWisePopup &&
                        <div>
                            <div className='dashboard_header flex-row'>
                                <h5 className='font16 sbold mb-0'><img src={icon} className='me-2' alt='' height={20} /> {title}</h5>
                                <button><img src={images.close} className='' alt='' /></button>
                            </div>
                            <InsurerWisePremiumPopup data={premium} data2={premium2} />
                        </div>
                    }
                </PopupAside>
                : null
            }
        </div >
    )
}
