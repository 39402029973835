import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement,
    Legend,
    Tooltip,
);

export const options = {
    cutout: "70%",
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
};

export default function InsurerWisePremiumPopup(props) {
    const graphData = {
        labels: props.data.map((r) => r.name),
        datasets: [
            {
                label: '',
                data: props.data.map((r) => r.percentage),
                backgroundColor: props.data.map((r) => r.color),
            },
        ],
    };
    return (
        <div className='pt-3'>
            <div className='custom_card p-3 mb-4'>
                <div className='' style={{ borderBottomWidth: 0 }}>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='portfolio_loss_block p-0 h-100'>
                                <p className='mb-2'>Total Premium amount</p>
                                <p className='med text-black mb-0'>₹ 177,981,830</p>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='portfolio_loss_block border-0 p-0 h-100'>
                                <p className='mb-2'>Number of Insurance Companies</p>
                                <p className='med text-black mb-0'>10</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='portfolio_loss_block border-0 p-0'>
                <div className='dashboard_header'>
                    <h5 className='font16 sbold mb-0'>List of Insurers</h5>
                </div>
                <div className='custom_card p-3 h-100'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <Doughnut data={graphData} options={options} />
                        </div>
                        <div className='col-lg-7'>
                            <div className='insurer_graph_labels'>
                                <div className="exposure_table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Insurer</th>
                                                <th className='text-end'>Percent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.data2.map((opt, i) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <h6 key={i} className='font12 med mb-0'><span className='portfolio_legend me-2' style={{ backgroundColor: opt.color }}></span> {opt.name}</h6>
                                                        </td>
                                                        <td className='light-text font12 text-end'>
                                                            {opt.percentage}%
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
