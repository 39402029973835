import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement,
    Legend,
    Tooltip,
);

export const options = {
    cutout: "70%",
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return (
                        `${context.formattedValue}%`
                    );
                }
            },
        },
    },
};

export default function InsurerWisePremium(props) {
    const graphData = {
        labels: props.data.map((r) => r.name),
        datasets: [
            {
                label: '',
                data: props.data.map((r) => r.percentage),
                backgroundColor: props.data.map((r) => r.color),
            },
        ],
    };
    return (
        <div className='pt-3'>
            <div className='row gy-3'>
                <div className={props.graphcol}>
                    <div className='insurer_graph_block'>
                        <Doughnut data={graphData} options={options} />
                    </div>
                </div>
                <div className={props.col}>
                    <ul className='insurer_graph_labels insights_list'>
                        {props.data.map((opt, i) => {
                            return (
                                <li key={i}><h6 className='font12 med mb-0'><span className='portfolio_legend me-2' style={{ backgroundColor: opt.color }}></span> {opt.name}</h6>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
