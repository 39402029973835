import React, { useState } from 'react'
import images from '../images'
import Badge from 'react-bootstrap/Badge';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';

export default function WellnessEvent(props) {
    const [active, setactive] = useState("1")
    const clicktab = (obj) => {
        setactive(obj.id)
    }
    return (
        <div className='dashboard_card'>
            <div className='service_calendar'>
                <div className='service_tabs showScroll'>
                    {props.data.map((data, ind) => {
                        return (
                            <button key={ind} className={data.id == active ? "active" : ""} onClick={() => clicktab(data)}>{data.name}</button>
                        )
                    })}
                </div>
            </div>
            <div className='portfolio_slider mt-4'>
                {props.data.filter((x) => x.id == active).map((data, ind) => {
                    return (
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={1.1}
                            mousewheel={true}
                            modules={[Mousewheel]}
                            key={ind}
                            breakpoints={{
                                1199: {
                                    slidesPerView: 3.7,
                                },
                                850: {
                                    slidesPerView: 2.5,
                                },
                                650: {
                                    slidesPerView: 2.2,
                                },
                                500: {
                                    slidesPerView: 1.7,
                                },
                            }}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        >
                            {data.data.map((opt, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <NavLink to="" className='service_card event_card eventCard flex-column'>
                                            <div className='event_image m-0'>
                                                <img src={opt.image} alt='' className='img-fluid w-100 d-d-block' />
                                            </div>
                                            <div className="event_content">
                                                <div className='service_date'>
                                                    <span className='font14 sbold primary-text'>{opt.date}</span>
                                                    <span className='font14 sbold  primary-text'>{opt.time}</span>
                                                </div>
                                                <h6 className='service_title font20 text-black sbold mb-1'>{opt.name}</h6>

                                                <p className='eventCard__location font12 med mb-0 text-light d-flex'>
                                                    <span className='icon'>
                                                        <img src={images.location} alt="" />
                                                    </span>
                                                    <span className='text'>  {opt.location}</span>
                                                </p>
                                                <p className='eventCard__content font14 ' style={{ color: "#515151" }}>{opt.text}</p>

                                                <div className='knowMore'>
                                                    <button className='knowMore__button font14 med'>Know more</button>
                                                </div>
                                                <div className='registerNow'>
                                                    <button className='registerNow__button font14 fw-semibold primary-text  '>
                                                        <span> Register Now</span>
                                                        <svg className="ms-2" width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.87508 5.49935L0.458415 2.08268C0.305637 1.9299 0.229248 1.75282 0.229248 1.55143C0.229248 1.35004 0.305637 1.17296 0.458415 1.02018C0.611193 0.867405 0.788276 0.791016 0.989665 0.791016C1.19105 0.791016 1.37048 0.869752 1.52796 1.02722L5.47925 4.97852C5.54869 5.05352 5.60078 5.13477 5.6355 5.22227C5.67022 5.30977 5.68758 5.40352 5.68758 5.50352C5.68758 5.60352 5.67022 5.69727 5.6355 5.78477C5.60078 5.87227 5.54869 5.95074 5.47925 6.02018L1.52796 9.97147C1.37048 10.1289 1.19453 10.2042 1.00008 10.1973C0.805637 10.1903 0.632026 10.1105 0.479248 9.95768C0.32647 9.8049 0.250081 9.62782 0.250081 9.42643C0.250081 9.22504 0.32647 9.04796 0.479248 8.89518L3.87508 5.49935Z" fill="#6FC72E" />
                                                        </svg>

                                                    </button>
                                                    {/* <Badge bg="warning" className='text-white primary-bg sbold' style={{ '--primary': opt.status == "Completed" ? "#e1e1e1" : "", '--white': opt.status == "Completed" ? "#515151" : "" }}>{opt.status}</Badge> */}
                                                </div>
                                            </div>
                                        </NavLink>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    )
                })}
            </div>
        </div>
    )
}
