import React, { useState } from 'react'
import images from '../images'
import DatePicker from "react-datepicker";

export default function ByAmount(props) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [activeTab, setactiveTab] = useState(props.data[0] || {});
    const onChange = (dates, data) => {
        console.log("data", data)
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (end) {
            setactiveTab(data)
        }
    };
    const clickTab = (data) => {
        if (data.type !== "custom") {
            setactiveTab(data)
        }
    }

    return (
        <>
            <div className='row g-3 claim_row'>
                {props.data.map((opt, i) => {
                    return (
                        <div key={i} className={`col ${opt.type == "custom" ? 'custom_col': ''}`}>
                            <button className={`custormBigTab__button ${opt.id == activeTab.id ? "active" : ""}`} onClick={() => clickTab(opt)}>
                                {opt.type == "custom" ?
                                    <div className='text-black font20 sbold mb-0 d-flex' style={{flexDirection: "column"}}>
                                        {endDate ?
                                            <div className='text-black font20 sbold mb-1'>{opt.value}</div>
                                            : null
                                        }
                                        <DatePicker
                                            selected={""}
                                            placeholderText='DD/MM/YYYY-DD/MM/YYYY'
                                            onChange={(e) => onChange(e, opt)}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            maxDate={new Date()}
                                            className='custom_range font12'
                                            dateFormat="dd MMM, yyyy"
                                        />
                                        {!endDate ?
                                            <p className='mb-0 mt-2 med font12'>{opt.name}</p>
                                            : null
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className='text-black font20 sbold mb-2'>{opt.value}</div>
                                        <p className='mb-0 med font12'>{opt.name}</p>
                                    </>
                                }
                            </button>
                        </div>
                    )
                })}

                {/* <div className='custormBigTab__button'>
                    <div className='custormBigTab__title font20 fw-semibold'>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            className='custom_range'
                            dateFormat="dd MMM, yyyy"
                        />
                    </div>
                    <p className='ma-0 font12'>Custom</p>

                </div> */}


            </div>
            {props.data.map((opt, i) => {
                if (opt.id == activeTab.id) {
                    return (
                        <div className="claim_table mt-3" key={i}>
                            <div className="exposure_table">
                                <table className=''>
                                    <thead>
                                        <tr>
                                            <th style={{ opacity: 0.8 }} width={"40%"} className='reg font10'>Category</th>
                                            <th style={{ opacity: 0.8 }} width={"20%"} className='reg font10'>Count</th>
                                            <th style={{ opacity: 0.8 }} width={"20%"} className='reg font10 text-center'>Incurred</th>
                                            <th style={{ opacity: 0.8 }} width={"20%"} className='reg font10 text-end'>Paid</th>
                                        </tr>
                                    </thead>
                                    {opt.data.length ?
                                        <tbody>
                                            {opt.data.map((data, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td className=''>
                                                            <h6 className='font12 med mb-0'>
                                                                <img src={data.icon} alt="" className='me-3' />
                                                                {data.title}
                                                            </h6>
                                                        </td>
                                                        <td className='font14 sbold text-black'>
                                                            <span className='custom_badge text-black font12 d-inline-block text-center' style={{ minWidth: "120px", color: "#696969" }}>{data.count} </span>
                                                        </td>
                                                        <td className='font12 med text-center' style={{ color: "#F30808" }}>
                                                            {data.incured}
                                                        </td>
                                                        <td className='font12 med text-end' style={{ color: "#0AD017" }}>
                                                            {data.paid}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        : null
                                    }
                                </table>
                                {opt.data.length == 0 ?
                                    <div className='p-3 text-center'>
                                        <p className='font14 light-text'><img src={images.help} alt='' width={20} className='me-2' /> No Records Found</p>
                                    </div>
                                    : null
                                }
                            </div>
                            {/* {showRangePicker ?
                                <div className='text-center'>
                                    <button className='primary-text p-2' onClick={() => setshowRangePicker(true)}>
                                        <img src={images.event_note} alt='' width={12} className='me-2' />
                                        Custom range
                                    </button>
                                </div>
                                : null
                            } */}
                        </div>
                    )
                }
            })}
        </>
    )
}
