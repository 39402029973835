import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import images from '../images'
import { useLocation } from 'react-router-dom';
import "./Header.css"

export default function Header() {
    const location = useLocation();
    const { pathname } = location;

    const [showLink, setshowLink] = useState(true)

    const toggleShowState = () => {
        setshowLink(!showLink)
    }
    console.log("pathname", pathname.split())
    return (
        <>
            <div className='loginHeader'>
                {showLink ?
                    <div className='loginHeader__top'>
                        <div className="container  ">
                            <div className='loginHeader__topbox d-flex justify-content-center align-items-center'>
                                <img className='globeimg' src={images.globe} alt="" />
                                <span> Use Microsoft Edge, Mozilla fox 11, Chrome 118 for Best possible Experience. 
                                    <Link className='loginHeader__topbox__link'>Learn More</Link>
                                </span>
                                <button className='loginHeader__topboxbtn' onClick={toggleShowState}> <img src={images.close} alt="" /></button>
                            </div>
                        </div>
                    </div> : null}
                <div className='loginHeader__main'>
                    <div className="container  ">
                        <div className="loginHeader__mainHolder d-flex justify-content-between align-items-center" >
                            <div className="loginHeader_logo">
                                <Link to="/" className='d-block' >  <img src={images.logo} alt="" width={110} /></Link>
                            </div>
                            <div className="loginHeader__content">
                                {pathname.split()[0] === "/support" || pathname.split()[0] == "/support/call" ?
                                    <Link to="/" className='loginHeader__btn'><img src={images.login} alt="" className='me-1' width="16" /> Back to Login</Link>

                                    :
                                    <Link to="/support" className='loginHeader__btn'> Customer Support  <img src={images.agent} alt="" className='ms-1' /></Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={showLink ? 'headerHeight ' : "headerHeight small"}></div>
        </>
    )
}
