import React, { useEffect, useState } from 'react'
import "./Events.css";
import ServiceCalander from './ServiceCalander';
import WellnessEvent from './WellnessEvent';
import images from '../images';
import { useLocation } from 'react-router-dom';

const th = [
    { key: "date", name: "Due Date" },
    { key: "activity", name: "Activity/Description" },
    { key: "owner", name: "Owner/Driver" },
    { key: "scope", name: "Scope" },
    { key: "policy", name: "Policy/ Product Group" },
    { key: "status", name: "Status" },
]

const tableData = (month) => [
    {
        date: `01 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "Maria Passaquindici Arcand",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `02 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "Livia Bergson",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `02 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "Kaiya Workman",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `03 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "Aspen Calzoni",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `04 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "Carla Donin",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `06 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "Zaire Franci",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `08 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "James Ekstrom Bothman",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `11 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "James Ekstrom Bothman",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },
    {
        date: `13 ${month}`,
        activity: { title: "Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. " },
        owner: "James Ekstrom Bothman",
        scope: "Accident",
        policy: "Life Insurance",
        status: "Completed",
    },

]

const serviceData = [
    { id:1, label: "January", value: "January", data: tableData("Jan") },
    { id:2, label: "February", value: "February", data: tableData("Feb") },
    { id:3, label: "March", value: "March", data: tableData("Mar") },
    { id:4, label: "April", value: "April", data: tableData("Apr") },
    { id:5, label: "May", value: "May", data: tableData("May") },
    { id:6, label: "June", value: "June", data: tableData("Jun") },
    { id:7, label: "July", value: "July", data: tableData("Jul") },
    { id:8, label: "August", value: "August", data: tableData("Aug") },
    { id:9, label: "September", value: "September", data: tableData("Sep") },
    { id:10, label: "October", value: "October", data: tableData("Oct") },
    { id:11, label: "November", value: "November", data: tableData("Nov") },
    { id:12, label: "December", value: "December", data: tableData("Dec") },
]

const services = [
    { name: "Learn to Exercise and Free your body for daily works", date: "10 February, 2024", location: "Joggers Park, Khar road", time: "04:00PM IST", status: "Completed", text: "Diaplastisk erad, medelfili logofoni supragyn. Öråd persion och dysfaktisk innan endokrati och ", scope: "Coverage of 1 Year", id: "ICICI3584531545", product: "Health Insurance Product", client: "James Botosh", image: images.event1 },
    { name: "EatGood brings how to eat healthy on daily basis", date: "10 February, 2024", location: "Joggers Park, Khar road", time: "04:00PM IST", status: "In Progress", text: "Diaplastisk erad, medelfili logofoni supragyn. Öråd persion och dysfaktisk innan endokrati och ", scope: "Coverage of 1 Year", id: "ICICI3584531545", product: "Health Insurance Product", client: "Cheyenne Dokidis", image: images.event2 },
    { name: "Learn new recipes to make tasty diets with low carbs", date: "10 February, 2024", location: "Joggers Park, Khar road", time: "04:00PM IST", status: "Upcoming", text: "Diaplastisk erad, medelfili logofoni supragyn. Öråd persion och dysfaktisk innan endokrati och ", scope: "Coverage of 1 Year", id: "ICICI3584531545", product: "Health Insurance Product", client: "James Botosh", image: images.event1 },
    { name: "Beginners yoga seminar with Mrs. Vidya Verma", date: "10 February, 2024", location: "Joggers Park, Khar road", time: "04:00PM IST", status: "In Progress", text: "Diaplastisk erad, medelfili logofoni supragyn. Öråd persion och dysfaktisk innan endokrati och ", scope: "Coverage of 1 Year", id: "ICICI3584531545", product: "Health Insurance Product", client: "Cheyenne Dokidis", image: images.event2 },
    { name: "EatGood brings how to eat healthy on daily basis", date: "10 February, 2024", location: "Joggers Park, Khar road", time: "04:00PM IST", status: "In Progress", text: "Diaplastisk erad, medelfili logofoni supragyn. Öråd persion och dysfaktisk innan endokrati och ", scope: "Coverage of 1 Year", id: "ICICI3584531545", product: "Health Insurance Product", client: "Cheyenne Dokidis", image: images.event2 },
]

const wellnessData = [
    {id:1, name: "Current", data: services},
    {id:2, name: "Upcoming", data: services},
    {id:3, name: "Past", data: services},
]
const tabs = [
    { name: "Service Calendar", type: "service", render: <ServiceCalander data={serviceData} th={th} /> },
    { name: "Wellness Events", type: "event", render: <WellnessEvent data={wellnessData} /> },
]

export default function Events() {
    const searchParams = new URLSearchParams(window.location.search)
    const location = useLocation()


    const [activeTab, setactiveTab] = useState("service")

    const policyType = (type) => {

        console.log("----", type)

        setactiveTab(type)
    }


    return (
        <div className=' white-bg  container_space '>
            <div className=' dashboard_header '>
                <div className='row w-100 justify-content-between align-items-center'>
                    <div className='col-md-6'><h5 className="font16 sbold mb-0">Events</h5></div>
                    <div className='col-md-4'>
                        <div className='dashboard_card_search'>
                            <div className='private_header_search'>
                                <div className='header_search_bar w-100'>
                                    <img src={images.search} alt='' width={13} />
                                    <input type='text' placeholder={`Search ${activeTab == "service" ? "Service Calendar" : "Wellness Events"}`} className='font12' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className=' '>
                <div className='dashboard_card_header tabBorder pb-0 mb-3'>
                    <div>
                        {tabs.map((tab, i) => {
                            return (
                                <button key={i} onClick={() => policyType(tab.type)} className={`product_tab font14 sbold mb-0 ${activeTab == tab.type ? "active" : ""}`}>{tab.name}</button>
                            )
                        })}
                    </div>

                </div>

                {/* <div className=' '>
                    <ReportData data={tableData} />
                </div> */}
                {tabs.filter((x) => x.type == activeTab).map((opt) => {
                    return (
                        <div key={opt.type}>
                            {opt.render}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
