import React from 'react'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Filler
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import images from '../images';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Filler
);


export default function PortfolioLoss(props) {
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: "#000",
                    font: {
                        size: 14,
                        family: "var(--font)",
                        fontWeight: 700
                    }
                },
            },
            y: {
                ticks: {
                    callback: function (val, index) {
                        return `${Number(val) / 10000000}cr`
                    },
                    color: "#858585",
                    font: {
                        size: 12,
                        family: "var(--font)"
                    },
                    stepSize: 40000000
                },
            },
        },
        elements: {
            line: {
                tension: 0
            },
            point: {
                radius: 0
            }
        },
        plugins: {
            filler: {
                propagate: true
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        interaction: {
            intersect: true
        }
    };
    
    
    const labels = [];
    const total = [];
    const percent = [];
    props.graphData.map((opt) => {
        labels.push(opt.title)
        total.push(opt.total)
        percent.push((Number(opt.percent) / 100) * Number(opt.total))
    })
    
    const data = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Loss%',
                borderWidth: 2,
                fill: "start",
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(255,19,19,0.4)");
                    gradient.addColorStop(1, "rgba(255,19,19,0.05)");
                    return gradient;
                },
                borderColor: "#F30808",
                data: percent,
            },
            {
                type: 'bar',
                label: 'Premium',
                backgroundColor: '#00B33C',
                data: total,
                borderWidth: 0,
                borderRadius: window.innerWidth < 575 ? 4: 10,
                barThickness: window.innerWidth < 575 ? 20: 40
            }
        ],
    };
    return (
        <div className='portfolio_graph'>
            <div style={{ height: "300px" }}>
                <Chart type='bar' options={options} data={data} />
            
            </div>
            {props.list ?
                <div className='text-end'>
                    <button>
                        <img src={images.format_list_bulleted} alt='' /> View list
                    </button>
                </div>
                : null
            }
        </div>
    )
}
