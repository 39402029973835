import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import images from '../images'
import "./PrivateHeader.css";
import PopupAside from '../common/PopupAside';
import Dropdown from 'react-bootstrap/Dropdown';
import Notification from './Notification';
import { logout } from '../../../App';

export default function PrivateHeader() {
    const navigate = useNavigate();
    const nav = [
        { label: "Home", link: "/home" },
        { label: "Policy", link: "/policy" },
        { label: "Claims", link: "/claims" },
        { label: "Report & Update", link: "/reports" },
        { label: "Regulations", link: "/regulations" }
    ]
    const events = [
        {
            id: 1, month: "January", event: 3, activity: "10+", events: [
                { name: "Event Name", date: "01 Nov", created: "Fri, 01 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
                { name: "Event Name", date: "02 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event2 },
                { name: "Event Name", date: "03 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
            ]
        },
        {
            id: 2, month: "Feruary", event: 2, activity: "10+", events: [
                { name: "Event Name", date: "01 Nov", created: "Fri, 01 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
                { name: "Event Name", date: "02 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event2 },
            ]
        },
        {
            id: 3, month: "March", event: 3, activity: "10+", events: [
                { name: "Event Name", date: "01 Nov", created: "Fri, 01 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
                { name: "Event Name", date: "02 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event2 },
                { name: "Event Name", date: "03 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
            ]
        },
        {
            month: "April", event: 5, activity: "10+", events: [
                { name: "Event Name", date: "01 Nov", created: "Fri, 01 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
                { name: "Event Name", date: "02 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event2 },
                { name: "Event Name", date: "03 Nov", created: "Fri, 03 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
                { name: "Event Name", date: "04 Nov", created: "Fri, 04 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
                { name: "Event Name", date: "05 Nov", created: "Fri, 05 Nov, 2023, 10:00AM", text: "Lörem ipsum plall sod och mir vapör uvadade krongen pronde i sor spevide kågt.", image: images.event1 },
            ]
        },
    ]
    const [openSearch, setOpenSearch] = useState(false)
    const [show, setShow] = useState(false)
    const [activeMonth, setActiveMonth] = useState(1)
    const [showMenu, setShowMenu] = useState(false)

    const handleClose = () => {
        setShow(false)
    }
    const goTo = (index, current) => {
        console.log(index, current)
    }

    const logout = () => {
        localStorage.clear()
        navigate("/")
        window.location.reload();
    }


    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }
    const HideMenu = (data) => {
        setShowMenu(false)
        if (data?.click) {
            window.open(data.link, "_self")
        }
    }

    useEffect(() => {
        document.querySelector(':root').classList.toggle('showMenu', showMenu);
    }, [showMenu])

    return (
        <div className='private_header'>
            <div className='container-fluid'>
                <div className='row align-items-center justify-content-between'>
                    <div className='private_header_search_outer'>
                        <Link to="/" className='mobileLogo'>
                            <img src={images.logo_trans} alt='CoverAdda' width={140} />
                        </Link>

                        <div className='private_header_search'>
                            <div className='header_search_bar w-100'>
                                <img src={images.search} alt='' width={13} />
                                <input type='text' placeholder='Search' />
                            </div>
                        </div>
                    </div>
                    <div className='private_header_account_outer text-end'>
                        <div className='private_header_account'>


                            <button className='folder_btn' onClick={() => setShow(true)}>
                                <span className='folderBtn__signal'></span>
                                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 13.5V12H1V7C1 5.79167 1.37153 4.72917 2.11458 3.8125C2.85764 2.89583 3.81944 2.32639 5 2.10417V1C5 0.722222 5.09722 0.486111 5.29167 0.291667C5.48611 0.0972222 5.72222 0 6 0C6.27778 0 6.51389 0.0972222 6.70833 0.291667C6.90278 0.486111 7 0.722222 7 1V2.10417C8.18056 2.32639 9.14236 2.89583 9.88542 3.8125C10.6285 4.72917 11 5.79167 11 7V12H12V13.5H0ZM5.99558 16C5.58186 16 5.22917 15.8531 4.9375 15.5594C4.64583 15.2656 4.5 14.9125 4.5 14.5H7.5C7.5 14.9167 7.35269 15.2708 7.05808 15.5625C6.76346 15.8542 6.40929 16 5.99558 16Z" fill="#6FC72E" />
                                </svg>
                            </button>
                            <Dropdown className='account_btn ms-3 '>
                                <Dropdown.Toggle variant="text" id="dropdown-basic" className='d-flex'>
                                    <div className='accountBtn__left'>
                                        <img src={images?.user} alt='user' />
                                    </div>
                                    <div className='accountBtn__cntnt'>
                                        <div className='fw-semibold font12 accountBtn__cntntTitle'>Admin</div>
                                        <span className='fw-medium  font10 '>Last login 2 weeks ago</span>
                                    </div>

                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">My Profile
                                        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.54183 5.97916L1.271 2.70833C1.06266 2.49999 0.958496 2.26041 0.958496 1.98958C0.958496 1.71874 1.06266 1.47916 1.271 1.27083C1.47933 1.06249 1.71891 0.958328 1.98975 0.958328C2.26058 0.958328 2.50251 1.06484 2.71554 1.27787L6.7085 5.27083C6.80572 5.37361 6.87863 5.48263 6.92725 5.59791C6.97586 5.71319 7.00016 5.84166 7.00016 5.98333C7.00016 6.125 6.97586 6.25347 6.92725 6.36875C6.87863 6.48402 6.80572 6.59027 6.7085 6.6875L2.71554 10.6805C2.50251 10.8935 2.26405 10.9965 2.00016 10.9896C1.73627 10.9826 1.50016 10.875 1.29183 10.6667C1.0835 10.4583 0.979329 10.2187 0.979329 9.94791C0.979329 9.67708 1.0835 9.4375 1.29183 9.22916L4.54183 5.97916Z" fill="#D9D9D9" />
                                        </svg>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Setting
                                        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.54183 5.97916L1.271 2.70833C1.06266 2.49999 0.958496 2.26041 0.958496 1.98958C0.958496 1.71874 1.06266 1.47916 1.271 1.27083C1.47933 1.06249 1.71891 0.958328 1.98975 0.958328C2.26058 0.958328 2.50251 1.06484 2.71554 1.27787L6.7085 5.27083C6.80572 5.37361 6.87863 5.48263 6.92725 5.59791C6.97586 5.71319 7.00016 5.84166 7.00016 5.98333C7.00016 6.125 6.97586 6.25347 6.92725 6.36875C6.87863 6.48402 6.80572 6.59027 6.7085 6.6875L2.71554 10.6805C2.50251 10.8935 2.26405 10.9965 2.00016 10.9896C1.73627 10.9826 1.50016 10.875 1.29183 10.6667C1.0835 10.4583 0.979329 10.2187 0.979329 9.94791C0.979329 9.67708 1.0835 9.4375 1.29183 9.22916L4.54183 5.97916Z" fill="#D9D9D9" />
                                        </svg>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={logout}>Logout
                                        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.54183 5.97916L1.271 2.70833C1.06266 2.49999 0.958496 2.26041 0.958496 1.98958C0.958496 1.71874 1.06266 1.47916 1.271 1.27083C1.47933 1.06249 1.71891 0.958328 1.98975 0.958328C2.26058 0.958328 2.50251 1.06484 2.71554 1.27787L6.7085 5.27083C6.80572 5.37361 6.87863 5.48263 6.92725 5.59791C6.97586 5.71319 7.00016 5.84166 7.00016 5.98333C7.00016 6.125 6.97586 6.25347 6.92725 6.36875C6.87863 6.48402 6.80572 6.59027 6.7085 6.6875L2.71554 10.6805C2.50251 10.8935 2.26405 10.9965 2.00016 10.9896C1.73627 10.9826 1.50016 10.875 1.29183 10.6667C1.0835 10.4583 0.979329 10.2187 0.979329 9.94791C0.979329 9.67708 1.0835 9.4375 1.29183 9.22916L4.54183 5.97916Z" fill="#D9D9D9" />
                                        </svg>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <button className="hamburger ms-3" onClick={toggleMenu}>
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {show ?
                <PopupAside position={"right"} size={"sm"} close={handleClose}>
                    <Notification onButtonClick={handleClose} />
                    {/* <div className='calendar_notification'>
                        <div className='portfolio_graph p-3'>
                            <div className='dashboard_card_header mb-3'>
                                <h6 className='font14 sbold mb-0'>
                                    <img src={images.event_note} alt='' style={{ verticalAlign: "bottom" }} className='me-2' /> Calendar</h6>
                                <div className='dashboard_card_search'>
                                    <button onClick={handleClose}>
                                        <img src={images.close} alt="x" />
                                    </button>
                                </div>
                            </div>
                            {events.filter((x) => x.id == activeMonth).map((ev, i) => {
                                return (
                                    <div className='event_slider' key={ev.id}>
                                        <div className='event_slider_header'>
                                            <button onClick={() => goTo(i - 1, i)} style={{ transform: "rotate(180deg)" }}><img src={images.navigate_next} alt='' /></button>
                                            <div className='text-center' style={{ whiteSpace: "nowrap" }}>
                                                <h6 className='text-black sbold mb-1 font14'>{ev.month}</h6>
                                                <p className='med mb-0 font10' style={{ color: "#858585" }}>{ev.event} Events</p>
                                            </div>
                                            <button onClick={() => goTo(i + 1, i)}><img src={images.navigate_next} alt='' /></button>
                                        </div>
                                        <div className='event_activities'>
                                            <NavLink to={"/my-calendar?type=service"} onClick={() => setShow(false)} className={"custom_card d-block p-3 mt-3"}>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='d-flex'>
                                                        <span><img src={images.activity} alt='' width={35} /></span>
                                                        <div className='ps-3'>
                                                            <p className='font14 text-black med mb-0'>10+ Service activities</p>
                                                            <p className='med mb-0 font10' style={{ color: "#858585" }}>{ev.month} Month</p>
                                                        </div>
                                                    </div>
                                                    <span><img src={images.navigate_next} alt='' /></span>
                                                </div>
                                            </NavLink>
                                            <div className='event_motification_list mt-3'>
                                                {ev.events.map((opt, ind) => {
                                                    return (
                                                        <div className={"notification_list custom_card d-block mb-3"} key={ind}>
                                                            <div className='card_image' style={{ height: 200 }}>
                                                                <img src={opt.image} alt='' className='img-fluid w-100' />
                                                            </div>
                                                            <div className='notification_item'>
                                                                <div className='notification_item_detail d-flex align-items-start'>
                                                                    <div className='service_date'>
                                                                        <span className='font14 sbold text-black'>{opt.date.split(" ")[0]}</span><br />
                                                                        <span className='font10 sbold text-black'>{opt.date.split(" ")[1]}</span>
                                                                    </div>
                                                                    <div className='notification_item_info ps-2'>
                                                                        <h6 className='sbold text-black font12 mb-1'>{opt.name}</h6>
                                                                        <p className='mb-3'>{opt.created}</p>
                                                                    </div>
                                                                </div>
                                                                <p className='font12 my-3' style={{ color: "#858585" }}>{opt.text}</p>

                                                                <NavLink to="/my-calendar?type=event" onClick={() => setShow(false)} className='common_btn btn_light w-100'>Know more
                                                                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-3'>
                                                                        <path d="M4.54183 5.97916L1.271 2.70833C1.06266 2.49999 0.958496 2.26041 0.958496 1.98958C0.958496 1.71874 1.06266 1.47916 1.271 1.27083C1.47933 1.06249 1.71891 0.958328 1.98975 0.958328C2.26058 0.958328 2.50251 1.06484 2.71554 1.27787L6.7085 5.27083C6.80572 5.37361 6.87863 5.48263 6.92725 5.59791C6.97586 5.71319 7.00016 5.84166 7.00016 5.98333C7.00016 6.125 6.97586 6.25347 6.92725 6.36875C6.87863 6.48402 6.80572 6.59027 6.7085 6.6875L2.71554 10.6805C2.50251 10.8935 2.26405 10.9965 2.00016 10.9896C1.73627 10.9826 1.50016 10.875 1.29183 10.6667C1.0835 10.4583 0.979329 10.2187 0.979329 9.94791C0.979329 9.67708 1.0835 9.4375 1.29183 9.22916L4.54183 5.97916Z" fill="var(--primary)" />
                                                                    </svg>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='mt-3'>
                                                <NavLink to="/my-calendar?type=service" onClick={() => setShow(false)} className='common_btn w-100'>View Calendar</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}

                </PopupAside>
                : null
            }
        </div>
    )
}
