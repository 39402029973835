import React, { useState } from 'react';
import images from '../images';
import './ForgotPasword.css'
import { Link } from 'react-router-dom';


function ForgotPasword(props) {
    const [showLink, setshowLink] = useState(false)

    const toggleShowState = () => {
        setshowLink(!showLink)
    }

    return (
        <div className='forgotPassword '>
            {!showLink ?
                <div className='customCard'>
                    <div className='customCard__heading'>
                        <h6 className='font20 sbold headingWithImg'>
                            <Link to="/" className='me-2' ><img src={images.backArrow} width="15" /></Link>
                            Forgot Password?</h6>
                        <p style={{ maxWidth: "250px" }} >Enter any one Employee code, Phone No or Corporate email</p>
                    </div>
                    <div className='customCard__form'>
                        <input type="text" className='formField' placeholder='Employee code, Phone No., Corporate email' />
                    </div>
                    <div className='customCard__button d-flex justify-content-between align-items-center'>
                        <button className='common_btn' onClick={toggleShowState}>Submit</button>
                        <Link to="/" className='btnText primary-text'>  Login </Link>
                    </div>
                </div>
                :

                <div className='customCard'>
                    <div className='customCard__Imgheading'>
                        <img src={images.checkCircle} />
                    </div>
                    <div className='customCard__heading'>
                        <h6 className='font20 sbold headingWithImg'>  Link Sent</h6>
                        <p >We have sent you request link in your Email address for Password Reset</p>
                    </div>

                    <div className='customCard__button d-flex justify-content-between align-items-center'>
                        <Link to="/" className='common_btn'>Okay</Link>
                    </div>
                </div>
            }
        </div>
    );
}

export default ForgotPasword;