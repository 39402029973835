import React from 'react'
import images from '../images'

export default function ReportData(props) {
    return (
        <div className='portfolio_slider mt-4'>
            <div className='dashboard_card'>
                <div className='dashboard_card_search'>
                    <div className='private_header_search'>
                        <div className='header_search_bar w-100'>
                            <img src={images.search} alt='' width={13} />
                            <input type='text' placeholder='Search Regulations' className='font12' />
                        </div>
                    </div>
                </div>
                <hr className='mb-0' />
                <div className="policy_table">
                    <table className='sort_table'>
                        <thead>
                            <tr>
                                <th>Report Name</th>
                                <th>Date of Publish</th>
                                <th className='empty'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((opt, i) => {
                                return (
                                    <tr key={i}>
                                        <td width={"40%"}>
                                            <div className='d-flex align-items-center'>
                                                <img src={images?.draft} alt='' className='me-2' />
                                                <p className='med text-black mb-0'>{opt.name}</p>
                                            </div>
                                        </td>
                                        <td width={"20%"}>
                                            <p className='med mb-0'>{opt.date}</p>
                                        </td>
                                        <td>
                                            <button>
                                                <img src={images?.navigate_next} alt='' />
                                            </button>
                                            <button className='ms-3'>
                                                <img src={images?.download} alt='' />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
