import React from 'react'
import images from '../images'
import './Home.css'
import { useState } from 'react'
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Link } from 'react-router-dom';

const commonDate = [
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
    { heading: "Main header", content: "Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. " },
]

const needHelp = [
    { title: "How to Register", imgsrc: images.poster1, dur: "01:00 Minutes" },
    { title: "How to Login", imgsrc: images.poster2, dur: "01:00 Minutes" },
    { title: "Forgot Password", imgsrc: images.poster3, dur: "01:00 Minutes" },
    { title: "Don’t know where to start?", imgsrc: images.poster4, dur: "01:00 Minutes" },
]
export default function Home() {
    const [showSignupPop, setshowSignupPop] = useState(false)
    const [activetab, setactivetab] = useState("login")
    const [error, setError] = useState(false);

    const [userDetail, setuserDetail] = useState("")
    const [value, setvalue] = useState({
        user: "",
        password:""
    })
    const toggleSignupPop = (tab) => {
        setactivetab(tab)
        setshowSignupPop(!showSignupPop)
    }
    const popupType = (tab) => {
        setactivetab(tab)
    }
    const [open, setOpen] = React.useState(false);

    const [controls, setControls] = React.useState(true);
    const [playsInline, setPlaysInline] = React.useState(true);
    const [autoPlay, setAutoPlay] = React.useState(false);
    const [loop, setLoop] = React.useState(false);
    const [muted, setMuted] = React.useState(false);
    const [disablePictureInPicture, setDisablePictureInPicture] =
        React.useState(false);
    const [disableRemotePlayback, setDisableRemotePlayback] =
        React.useState(false);

    const [crossOrigin, setCrossOrigin] = React.useState("");
    const [preload, setPreload] = React.useState("");

    const [showPassword, setshowPassword] = useState(false)
    const [otpScreen, setotpScreen] = useState(false)
    const togglePasswordState = () => {
        setotpScreen(false)
        setshowPassword(!showPassword)
    }
    const toggleOtpState = () => {
        setshowPassword(false)
        setotpScreen(!otpScreen)
    }
    const onValueChange = (e)=>{
        setvalue({...value, [e.target.name] : e.target.value})
    }
    const onDetailsubmit = () => {
        if (value.user == '') {
            setError(true);
        } else {
            togglePasswordState()
        }
    }
    const login = () => {
        localStorage["AUTH"] = true
        window.open("/home", "_self")
    }

    return (
        <>
            <section className='homeBanner' >
                <img className='homeBanner__img' src={images.banner} alt="" />
                <div className='container'>
                    <div className='homeBanner__box'>
                        {!otpScreen ?
                            <>
                                {!showPassword ?
                                    <div className='customCard'>
                                        <div className='customCard__heading'>
                                            <h6 className='font20 sbold'> Corporate Login</h6>
                                            <p>Use any one Employee code, Phone No or Corporate email for Login</p>
                                        </div>
                                        <div className='customCard__form'>
                                            <input type="text" className='formField' name='user' value={value.user} onChange={(e) => onValueChange(e)} placeholder='Employee code, Phone No., Corporate email' />
                                            {error && value.user == "" ?
                                                <div className='error d-flex mt-1'>
                                                    <img className='error__img' src={images.warning} width="18" />
                                                    <div className='error__cntnt'> Please Enter Details</div>
                                                </div> : null
                                            }
                                        </div>
                                        <div className='customCard__button d-flex justify-content-between align-items-center'>
                                            <button className='common_btn' onClick={onDetailsubmit}>Next</button>
                                            <button><img src={images.help} className='me-1' alt="" /> Help</button>
                                        </div>
                                    </div>
                                    :
                                    <div className='customCard customCard__mb3'>
                                        <div className='customCard__heading'>
                                            <h6 className='font20 sbold headingWithImg'>
                                                <button onClick={togglePasswordState} className='me-2' ><img src={images.backArrow} width="15" /></button>
                                                John Doe</h6>
                                            <p style={{ maxWidth: "250px" }} >Enter your Password</p>
                                        </div>
                                        <div className='customCard__form'>
                                            <input type="password" name='password' value={value.password} onChange={(e) => onValueChange(e)} className='formField' placeholder='Password' />
                                        </div>
                                        <Link className='customCard__link' to="/forgot-password">Forgot Password?</Link>
                                        <div className='customCard__button d-flex justify-content-between align-items-center'>
                                            <button className='common_btn' onClick={login}  >Next</button>
                                            <button className='btnText primary-text' onClick={toggleOtpState}> <img src={images.chatBubble} className='me-2' />    Login through OTP </button>
                                        </div>
                                    </div>}
                            </> :

                            <div className='customCard customCard__mb3'>
                                <div className='customCard__heading'>
                                    <h6 className='font20 sbold headingWithImg'>
                                        <button onClick={toggleOtpState} className='me-2' ><img src={images.backArrow} width="15" /></button>
                                        John Doe</h6>
                                    <p style={{ maxWidth: "250px" }} >We have sent you an OTP on your Email address <strong>john.doe@sgassciates.com</strong></p>
                                </div>
                                <div className='customCard__form'>
                                    <input type="text" className='formField' placeholder='Enter OTP' />
                                </div>
                                <button className='customCard__link'><img src={images.replay} className='me-2' />  Resend </button>
                                <div className='customCard__button d-flex justify-content-between align-items-center'>
                                    <button className='common_btn' onClick={login}  >Submit</button>
                                    <button className='btnText primary-text' onClick={togglePasswordState}> <img src={images.encrypted} className='me-2' />    Login Password</button>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>
            <section className='commonSection commonSection__grey '>
                <div className='container'>
                    <div className='commonSection__heading' >
                        <h2 className='font20'>Main header</h2>
                        <p className='font14'>Lörem ipsum dekahet pona, all buskade inte vadälogi. Milingar sesat sedon hyngen ofase. Sysade bisa gykar kav därför att seryska, prer. </p>
                    </div>
                </div>
            </section>
            <section className='commonSection commonSection__pad80'>
                <div className='container'>
                    <div className='homeRow__80'>
                        {commonDate.map((data, index) => {
                            return (
                                <div key={index} className=' '>
                                    <div className='homeDetailBox' >
                                        <h2 className='font20'>{data.heading}</h2>
                                        <p className='font14'>{data.content}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    <div className='text-center'>
                        <button className='common_btn'>Know more</button>
                    </div>

                </div>
            </section>
            <section className='commonSection commonSection__grey '>
                <div className='container'>
                    <div className='commonSection__heading d-flex justify-content-between align-items-center mb-60' >
                        <div>
                            <h2 className='font20'>Need Help ?</h2>
                            <p className='font14'>Know how to login to your corporate account.</p>
                        </div>
                        <div><button className="common_btn">Read Manual</button></div>
                    </div>


                    <div className="needHelpRow">
                        {needHelp.map((data, index) => {
                            return (
                                <div key={index} className="needHelpRow__box">
                                    <div className="needHelpRow__ImgHolder">
                                        <img src={data.imgsrc} />
                                        <button onClick={() => setOpen(true)}><img src={images.play} alt="" /></button>
                                    </div>
                                    <h6 className="font14 needHelpRow__boxTitle">{data.title}</h6>
                                    <p>{data.dur}</p>
                                </div>
                            )
                        })}

                    </div>

                </div>
            </section>


            <Lightbox
                open={open}
                close={() => setOpen(false)}
                plugins={[Video, Captions]}
                video={{
                    controls,
                    playsInline,
                    autoPlay,
                    loop,
                    muted,
                    disablePictureInPicture,
                    disableRemotePlayback,
                    crossOrigin,
                    preload,
                }}
                slides={[
                    {
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: images.banner,
                        controls: true,
                        title: "How to Register",
                        description: "Learn how to Register your Profile in few steps",
                        sources: [
                            {
                                src: images.demo1,
                                type: "video/mp4",
                            },
                        ],
                    },
                    {
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: images.banner,
                        controls: true,
                        title: "How to Register",
                        description: "Learn how to Register your Profile in few steps",
                        sources: [
                            {
                                src: images.demo2,
                                type: "video/mp4",
                            },
                        ],
                    },
                ]}
            />

        </>
    )
}
