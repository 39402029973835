export default {
    logo: require("../../assets/images/logo.svg").default, 
    logogrey: require("../../assets/images/coveraddaGrey.svg").default, 
    globe: require("../../assets/images/globe.svg").default, 
    close: require("../../assets/images/close.svg").default, 
    agent: require("../../assets/images/support_agent.svg").default, 
    banner: require("../../assets/images/banner.jpg"), 
    help: require("../../assets/images/help.svg").default, 
    demo1: require("../../assets/images/demo1.mp4"), 
    demo2: require("../../assets/images/demo2.mp4"), 
    poster1: require("../../assets/images/poster1.jpg"), 
    poster2: require("../../assets/images/poster2.jpg"), 
    poster3: require("../../assets/images/poster3.jpg"), 
    poster4: require("../../assets/images/poster4.jpg"), 
    play: require("../../assets/images/play_arrow.svg").default, 
    backArrow: require("../../assets/images/arrow_back.svg").default, 
    checkCircle: require("../../assets/images/check_circle.svg").default, 
    chatBubble: require("../../assets/images/chat_bubble.svg").default, 
    encrypted: require("../../assets/images/encrypted.svg").default, 
    replay: require("../../assets/images/replay.svg").default, 
    call: require("../../assets/images/call.svg").default, 
    chat: require("../../assets/images/chat.svg").default, 
    callLog: require("../../assets/images/call_log.svg").default, 
    login: require("../../assets/images/login.svg").default, 
    warning: require("../../assets/images/warning.svg").default, 
    
}