import React from 'react'
import images from '../images'

export default function Notification({onButtonClick}) {
    return (
        <div className='dashboard_card_notification'>
            <div className='notification_header mb-3'>
                <button className='med text-black d-flex justify-content-between w-100 py-2'>
                    <span>
                        <img src={images.notifications} alt='' className='me-2' />
                        Notification
                    </span>
                    <span>
                        <img src={images.close} onClick={onButtonClick} width="10" alt='' />
                    </span>
                </button>
            </div>
            <div className='notification_list'>
                <div className='custom_card mb-3'>
                    <div className='notification_item'>
                        <div className='notification_item_detail'>
                            <div className='notification_item_icon'>
                                <img src={images.emergency_home} alt='' width={15} />
                            </div>
                            <div className='notification_item_info'>
                                <h6 className='sbold text-black font12 mb-1'>10 Days remaining!</h6>
                                <p className='mb-3'>Your window is opened for 10 days for any New Endorsement</p>
                                <button className='common_btn btn_light w-100'>+ New Endorsement</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom_card'>
                    <div className='card_image'>
                        <img src={images.event_img} alt='' className='img-fluid' />
                    </div>
                    <div className='notification_item'>
                        <div className='notification_item_detail d-flex align-items-start'>
                            <div className='notification_item_icon'>
                                <img src={images.podium} alt='' width={15} />
                            </div>
                            <div className='notification_item_info ps-2'>
                                <h6 className='sbold text-black font12 mb-1'>Event Name</h6>
                                <p className='mb-3'>01 Nov, 2023, 10:00AM</p>
                            </div>
                        </div>
                        <button className='common_btn btn_light w-100'>Know more
                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-3'>
                                <path d="M4.54183 5.97916L1.271 2.70833C1.06266 2.49999 0.958496 2.26041 0.958496 1.98958C0.958496 1.71874 1.06266 1.47916 1.271 1.27083C1.47933 1.06249 1.71891 0.958328 1.98975 0.958328C2.26058 0.958328 2.50251 1.06484 2.71554 1.27787L6.7085 5.27083C6.80572 5.37361 6.87863 5.48263 6.92725 5.59791C6.97586 5.71319 7.00016 5.84166 7.00016 5.98333C7.00016 6.125 6.97586 6.25347 6.92725 6.36875C6.87863 6.48402 6.80572 6.59027 6.7085 6.6875L2.71554 10.6805C2.50251 10.8935 2.26405 10.9965 2.00016 10.9896C1.73627 10.9826 1.50016 10.875 1.29183 10.6667C1.0835 10.4583 0.979329 10.2187 0.979329 9.94791C0.979329 9.67708 1.0835 9.4375 1.29183 9.22916L4.54183 5.97916Z" fill="var(--primary)" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
