import React, { useState } from 'react'
import images from '../images';
import Accordion from 'react-bootstrap/Accordion';
import "./Claims.css";
import ByAmount from './ByAmount';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import PopupAside from '../common/PopupAside';
import ActivePolicies from '../Home/ActivePolicies';
import { activePolicies } from '../Home';


export default function Claims() {

  const insights = [
    {
      title: "New Claims Reported", total: "250", icon: images?.assignment_add, data: [
        { title: "Fire", total: "5,000", icon: images.local_fire_department },
        { title: "Liability", total: "5,000", icon: images.real_estate_agent },
        { title: "Terrorism", total: "2,000", icon: images.domino_mask },
        { title: "Marine", total: "2,000", icon: images.directions_boat },
        { title: "Health", total: "10,000", icon: images.ecg_heart },
        { title: "Motor", total: "1,000", icon: images.directions_car },
      ]
    },
    {
      title: "Claims Fully Settled", total: "200", icon: images?.assignment_turned_in, data: [
        { title: "Fire", total: "₹ 258,000", icon: images.local_fire_department },
        { title: "Liability", total: "₹ 256,000", icon: images.real_estate_agent },
        { title: "Terrorism", total: "₹ 128,000", icon: images.domino_mask },
        { title: "Marine", total: "₹ 500,000", icon: images.directions_boat },
        { title: "Health", total: "₹ 800,000", icon: images.ecg_heart },
        { title: "Motor", total: "₹ 200,000", icon: images.directions_car },
      ]
    },
    {
      title: "Claims Withdrawn/Rejected", total: "50", icon: images?.cancel, data: [
        { title: "Fire", total: "02", icon: images.local_fire_department },
        { title: "Liability", total: "04", icon: images.real_estate_agent },
        { title: "Terrorism", total: "05", icon: images.domino_mask },
        { title: "Marine", total: "00", icon: images.directions_boat },
        { title: "Health", total: "00", icon: images.ecg_heart },
        { title: "Motor", total: "03", icon: images.directions_car },
      ]
    },
  ]

  const amountdata = [
    {
      id: 1, name: "Above 50 Million", value: "00", data: []
    },
    { id: 2, name: "Between 10-50 Million", value: "00", data: [] },
    { id: 3, name: "Between 2.5-10 Million", value: "00", data: [] },
    {
      id: 4, name: "Between 0.5-2.5 Million", value: "01", data: [
        { title: "Liability", count: 70, incured: "10,00", paid: "10,00", icon: images.real_estate_agent },
      ]
    },
    {
      id: 5, name: "Below 0.5 Million", value: "13", data: [
        { title: "Fire", count: 20, incured: "13,00", paid: "13,00", icon: images.local_fire_department },
        { title: "Liability", count: 70, incured: "10,00", paid: "10,00", icon: images.real_estate_agent },
        { title: "Terrorism", count: 80, incured: "15,00", paid: "15,00", icon: images.domino_mask },
        { title: "Marine", count: 55, incured: "12,50", paid: "12,50", icon: images.directions_boat },
        { title: "Health", count: 80, incured: "6,000", paid: "6,000", icon: images.ecg_heart },
        { title: "Motor", count: 10, incured: "13,00", paid: "13,00", icon: images.directions_car },
      ]
    },
  ]
  const agedata = [
    {
      id: 1, name: "In six months", value: "02", data: [
        { title: "Fire", count: 20, incured: "13,00", paid: "13,00", icon: images.local_fire_department },
        { title: "Liability", count: 70, incured: "10,00", paid: "10,00", icon: images.real_estate_agent },]
    },
    {
      id: 2, name: "In three months", value: '04', data: [
        { title: "Fire", count: 20, incured: "13,00", paid: "13,00", icon: images.local_fire_department },
        { title: "Liability", count: 70, incured: "10,00", paid: "10,00", icon: images.real_estate_agent },
        { title: "Terrorism", count: 80, incured: "15,00", paid: "15,00", icon: images.domino_mask },
        { title: "Marine", count: 55, incured: "12,50", paid: "12,50", icon: images.directions_boat },]
    },
    {
      id: 3, name: "In last 1 month", value: "10", data: [
        { title: "Fire", count: 20, incured: "13,00", paid: "13,00", icon: images.local_fire_department },
        { title: "Liability", count: 70, incured: "10,00", paid: "10,00", icon: images.real_estate_agent },
        { title: "Terrorism", count: 80, incured: "15,00", paid: "15,00", icon: images.domino_mask },
        { title: "Marine", count: 55, incured: "12,50", paid: "12,50", icon: images.directions_boat },
        { title: "Health", count: 80, incured: "6,000", paid: "6,000", icon: images.ecg_heart },
        { title: "Motor", count: 10, incured: "13,00", paid: "13,00", icon: images.directions_car },]
    },
    {
      id: 4, name: "Custom", type: "custom", value: "23", data: [
        { title: "Fire", count: 20, incured: "13,00", paid: "13,00", icon: images.local_fire_department },
        { title: "Liability", count: 70, incured: "10,00", paid: "10,00", icon: images.real_estate_agent },
        { title: "Terrorism", count: 80, incured: "15,00", paid: "15,00", icon: images.domino_mask },
        { title: "Marine", count: 55, incured: "12,50", paid: "12,50", icon: images.directions_boat },
        { title: "Health", count: 80, incured: "6,000", paid: "6,000", icon: images.ecg_heart },
        { title: "Motor", count: 10, incured: "13,00", paid: "13,00", icon: images.directions_car },
      ]
    },
  ]

  const tableData = [
    { name: "Issuance of Discharge voucher by insurer", number: "HSJC1234567890", date: "15 Dec, 2023", client: "Emery Bergson" },
    { name: "Intimation of Claim to insurer", number: "JSOFS1234567890", date: "15 Dec, 2023", client: "Mira Lubin" },
    { name: "Followup with insured for pending document reminder", number: "KAJFA1234567890", date: "02 Feb, 2024", client: "Allison Dokidis" },
    { name: "Intimation of claim to insurer", number: "HSJC1234567890", date: "07 Nov, 2023", client: "Ashlynn Torff" },
    { name: "Issuance of LOR", number: "HSJC1234567890", date: "15 Dec, 2023", client: "Aspen Westervelt" },
    { name: "Issuance of LOR", number: "JSOFS1234567890", date: "10 Jan, 2023", client: "Kadin Stanton" },
    { name: "Confirmation of assessment to Surveyor", number: "HSJC1234567890", date: "15 Dec, 2023", client: "Paityn Mango" },
    { name: "Perform Basic Checks", number: "JSOFS1234567890", date: "15 Dec, 2023", client: "Phillip George" },
  ]

  const tabs = [
    { name: "By Amount", type: "amount", render: <ByAmount data={amountdata} /> },
    { name: "By Age", type: "age", render: <ByAmount data={agedata} showRange={true} /> },
  ]
  const tabs2 = [
    { name: "Master Policies", type: "Master", render: "" },
    { name: "Local Policies", type: "Local", render: "" },
  ]
  const claimspartially = [
    { title: "Fire", count: "02", incurred: "₹ 10,000", paid: "₹ 20,000", icon: images.local_fire_department },
    { title: "Liability", count: "01", incurred: "₹ 5,000", paid: "₹ 15,000", icon: images.real_estate_agent },

  ]

  const [activeTab, setactiveTab] = useState("amount")
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState("")
  const [icon, seticon] = useState("")
  const [activePolicy, setactivePolicy] = useState(false)
  const showPolicyPopup = (text, icon) => {
      setShow(true)
      setactivePolicy(true)
      setTitle(text)
      seticon(icon)
  }
  const handleClose = () => {
      setShow(false)
      setactivePolicy(false)
  }

  const policyType = (type) => {
    setactiveTab(type)
  }
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!', eventKey),
    );

    return (
      <button
        type="button"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <div className=' policy_section  '>
      <div className='white-bg  container_space  '>
        <div className='dashboard_header'>
          <h5 className='font16 sbold mb-0'>Claims Summary</h5>
        </div>
        <div className='row gy-4 mb-4'>
          {insights.map((opt, i) => {
            return (
              <div className='col-xl-4 col-md-6' key={i}>
                <div className='custom_card p-3'>

                  <Accordion className='custom_accordion'>
                    <Accordion.Item eventKey={i}>
                      {/* <Accordion.Header> */}

                      <button className='accordion-button collapsed' type='button'>
                        <button className='font14 med mb-0 text-start' style={{ flexGrow: 1 }} onClick={() => showPolicyPopup(opt.title, opt.icon)}>
                          <img src={opt.icon} alt="" className='me-3' />
                          {opt.title}
                        </button>
                        <h5 className='font14 sbold text-black mb-0'>
                          {opt.total}
                          <CustomToggle eventKey={i}><img src={images.navigate_next} alt="" className='ms-2 arrow_icon' style={{ verticalAlign: "inherit" }} /></CustomToggle>
                        </h5>
                      </button>
                      {/* </Accordion.Header> */}
                      <Accordion.Body>
                        <ul className='insights_list'>
                          {opt.data.map((data, ind) => {
                            return (
                              <li key={ind}>
                                <h6 className='font12 med mb-0'>
                                  <img src={data.icon} alt="" className='me-3' />
                                  {data.title}
                                </h6>
                                <span className='custom_badge'>
                                  {data.total}
                                </span>
                              </li>
                            )
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            )
          })}
        </div>

        <div className='custom_card border_card p-3 claim_reported h-100 align-items-stretch'>
          <div className="claim_reported_header d-flex justify-content-between flex-column ">
            <div className='top'>
              <img src={images.balance} alt="" />
            </div>
            <div className='bottom'>
              <h5 className="font20 sbold text-black">  12</h5>
              <p className="font14 med mb-0">Claims Reported for last 12 months</p>
            </div>
          </div>
          <div className="claim_reported_table h-100">
            <table className='table claimTable m-0'>
              <thead>
                <tr>
                  <td>Category</td>
                  <td>Count</td>
                  <td>Incurred</td>
                  <td className='text-end'>Paid</td>
                </tr>
              </thead>
              <tbody>
                {claimspartially.map((data, indx) => {
                  return (
                    <tr key={indx}>
                      <td>
                        <h6 className="font12 med mb-0 fw-semibold">
                          <img src={data.icon} alt="" className="me-3" /> {data.count}</h6>
                      </td>
                      <td> <span className='custom_badge'>{data.count}</span></td>
                      <td style={{ color: "rgb(243, 8, 8)" }}>{data.incurred}</td>
                      <td className='text-end fw-semibold' style={{ color: "#00B33C" }}>{data.paid}</td>
                    </tr>
                  )
                })}

              </tbody>

            </table>
          </div>

        </div>

      </div>
      <div className='p-4'>
        <div className='custom_card p-3'>
          <div className='dashboard_card'>
            <h5 className='font14 sbold mb-4'>Outstanding claims</h5>
            <div className='dashboard_card_header pb-0 mb-4'>
              <div>
                {tabs.map((tab, i) => {
                  return (
                    <button key={i} onClick={() => policyType(tab.type)} className={`product_tab font12 sbold mb-0 ${activeTab == tab.type ? "active" : ""}`}>{tab.name}</button>
                  )
                })}
              </div>
            </div>
            {tabs.filter((x) => x.type == activeTab).map((opt) => {
              return (
                <div key={opt.type}>
                  {opt.render}
                </div>
              )
            })}
          </div>
        </div>
      </div>


      <div className='white-bg  container_space   '>
        <div className='container-fluid p-0'>
          <div className='dashboard_card'>
            <div className='dashboard_card_header pb-0 mb-4'>
              <h5 className='font16 sbold mb-0'>List of Claims pending tasks </h5>

            </div>
            <div className="mb-4">
              <div className="row">
                <div className="col-md-4">
                  <div className='dashboard_card_search'>
                    <div className='private_header_search'>
                      <div className='header_search_bar w-100'>
                        <img src={images.search} alt='' width={13} />
                        <input type='text' placeholder='Search Claim' className='font12' />
                      </div>
                    </div>
                  </div></div>
                <div className="">
                </div>

              </div>
            </div>

            <div className="policy_table">
              <table className=' table pendingClaimTable'>
                <thead>
                  <tr className=''>
                    <th className='font12'>Claim No</th>
                    <th className='font12'>Task Name</th>
                    <th className='font12'>Client Name</th>
                    <th className='font12'>Due date</th>
                    <th className='font12 text-end'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((opt, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <p className='font14 med text-black mb-0'>{opt.number}</p>
                        </td>
                        <td>
                          <p className='font14 mb-0'>{opt.name}</p>
                        </td>
                        <td>
                          <p className='font14 mb-0'>{opt.client}</p>
                        </td>
                        <td className='med text-black'>
                          <p className='font14 mb-0'>{opt.date}</p>
                        </td>
                        <td className='med text-black text-end'>
                          <p className='font14 primary-text'>View
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.8417 11.4844L9.40536 9.04805C9.27715 8.91986 9.21205 8.76421 9.21005 8.58112C9.20804 8.39803 9.27314 8.24039 9.40536 8.10818C9.53757 7.97596 9.69421 7.90985 9.8753 7.90985C10.0564 7.90985 10.213 7.97596 10.3452 8.10818L13.1854 10.9483C13.2647 11.0276 13.3216 11.1102 13.356 11.1959C13.3905 11.2816 13.4077 11.3778 13.4077 11.4844C13.4077 11.5909 13.3905 11.6871 13.356 11.7728C13.3216 11.8586 13.2647 11.9411 13.1854 12.0204L10.3452 14.8606C10.217 14.9888 10.0614 15.0539 9.8783 15.0559C9.69521 15.0579 9.53757 14.9928 9.40536 14.8606C9.27314 14.7283 9.20703 14.5717 9.20703 14.3906C9.20703 14.2095 9.27314 14.0529 9.40536 13.9207L11.8417 11.4844Z" fill="#6FC72E" />
                            </svg>
                          </p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      
      {show ?
                <PopupAside position={"right"} size={"lg"} close={handleClose}>
                    {activePolicy &&
                        <div>
                            <div className='dashboard_header flex-row'>
                                <h5 className='font16 sbold mb-0'><img src={icon} className='me-2' alt='' /> {title}</h5>
                                <button onClick={handleClose}><img src={images.close} className='' alt='' /></button>
                            </div>
                            <ActivePolicies data={activePolicies} />
                        </div>
                    }
                </PopupAside>
                : null
            }
    </div>
  )
}
