import React from 'react'
import images from '../images'

export default function ActivePolicies(props) {
    return (
        <div className='active_policies'>
            <div className='custom_card p-3 dashboard_header mb-3'>
                <h6 className='font12 sbold mb-0'>Total Active</h6>
                <h6 className='font12 sbold mb-0'>25,000</h6>
            </div>
            <div className='active_policies_table'>
                <table>
                    <thead>
                        <tr>
                            <th className='font12 med'>Riders</th>
                            <th className='font12 med'>Premium/Validity</th>
                            <th className='font12 med'>Policy type/no</th>
                            <th className='font12 med' width="30"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((opt, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <h6 className='font12 sbold mb-0'>{opt.rider}</h6>
                                        <p className='light-text med font12'>{opt.number} Riders</p>
                                    </td>
                                    <td>
                                        <h6 className='font12 sbold mb-0'>{opt.premium}</h6>
                                        <p className='light-text med font12'>{opt.date}</p>
                                    </td>
                                    <td>
                                        <h6 className='font12 sbold mb-0'>{opt.type}</h6>
                                        <p className='light-text med font12'>{opt.policy} </p>
                                    </td>
                                    <td>
                                        <button>
                                            <img src={images.navigate_next} alt="" className='ms-2 arrow_icon' style={{ verticalAlign: "inherit" }} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
