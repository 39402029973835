import React, { useState } from 'react'
import images from '../images'
import { Accordion } from 'react-bootstrap'
import { CustomToggle, activePolicies, bifurcation, graphData, graphData2, insights, premium, premium2 } from '../Home'
import ActivePolicies from '../Home/ActivePolicies'
import PopupAside from '../common/PopupAside'
import InsurerWisePremium from '../Home/InsurerWisePremium'
import InsurerWisePremiumPopup from '../Home/InsurerWisePremiumPopup'
import "./Policy.css";

export default function Policy() {
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [type, settype] = useState("")
    const [icon, seticon] = useState("")
    const [activePolicy, setactivePolicy] = useState(false)
    const [insuranceWisePopup, setinsuranceWisePopup] = useState(false)
    const showPolicyPopup = (text, icon) => {
        setShow(true)
        setactivePolicy(true)
        setTitle(text)
        seticon(icon)
    }
    const handleClose = () => {
        setShow(false)
        setactivePolicy(false)
        setinsuranceWisePopup(false)
    }

    const showInsuranceWisePopup = (text, icon, type) => {
        setShow(true)
        setinsuranceWisePopup(true)
        setTitle(text)
        seticon(icon)
        settype(type)
    }
    return (
        <div className='dashboard_main'>
            <div className='white-bg container_space'>
                <div className='dashboard_header'>
                    <h5 className='font16 sbold mb-0'>Policy Summary</h5>
                </div>
                <div className='row gy-4 mb-4'>
                    {insights.map((opt, i) => {
                        return (
                            <div className='col-xl-4 col-md-6' key={i}>
                                <div className='custom_card p-3'>
                                    <Accordion className='custom_accordion'>
                                        <Accordion.Item eventKey={i}>
                                            {/* <Accordion.Header> */}

                                            <button className='accordion-button collapsed' type='button'>
                                                <button className='font14 med mb-0 text-start' style={{ flexGrow: 1 }} onClick={() => showPolicyPopup(opt.title, opt.icon)}>
                                                    <img src={opt.icon} alt="" className='me-3' />
                                                    {opt.title}
                                                </button>
                                                <h5 className='font14 sbold text-black mb-0'>
                                                    {opt.total}
                                                    <CustomToggle eventKey={i}><img src={images.navigate_next} alt="" className='ms-2 arrow_icon' style={{ verticalAlign: "inherit" }} /></CustomToggle>
                                                </h5>
                                            </button>
                                            {/* </Accordion.Header> */}
                                            <Accordion.Body>
                                                <ul className='insights_list'>
                                                    {opt.data.map((data, ind) => {
                                                        return (
                                                            <li key={ind}>
                                                                <h6 className='font12 med mb-0'>
                                                                    <img src={data.icon} alt="" className='me-3' />
                                                                    {data.title}
                                                                </h6>
                                                                <span className='custom_badge'>
                                                                    {data.total}
                                                                </span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>

            <div className='container_space'>
                <div className='row gy-4'>
                    <div className='col-xl-6'>
                        <div className='custom_card p-4 h-100'>
                            <div className='portfolio_loss_block border-0 p-0'>
                                <div className='dashboard_header flex-row'>
                                    <h5 className='font16 sbold mb-0'>Insurer Wise Premium</h5>
                                    <button onClick={() => showInsuranceWisePopup("Insurer Wise Premium", images.shield, "insurer")} className='font12' style={{ color: "#6FC72E", whiteSpace: "nowrap" }}>View all <svg width="6" height="10" className='ms-1' viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.82984 4.97916L0.414774 1.56407C0.243829 1.39314 0.157024 1.20298 0.154357 0.993572C0.151677 0.784183 0.238482 0.59135 0.414774 0.415072C0.591052 0.23878 0.782551 0.150635 0.989274 0.150635C1.196 0.150635 1.38985 0.241128 1.57084 0.422114L5.47563 4.32693C5.56752 4.42436 5.63643 4.52537 5.68236 4.62997C5.7283 4.73455 5.75127 4.85234 5.75127 4.98332C5.75127 5.11431 5.7283 5.23209 5.68236 5.33668C5.63643 5.44127 5.56752 5.53951 5.47563 5.63138L1.57084 9.5362C1.3952 9.71184 1.20615 9.79752 1.00369 9.79324C0.801245 9.78897 0.611885 9.6987 0.435607 9.52241C0.259315 9.34613 0.171169 9.15463 0.171169 8.94791C0.171169 8.74118 0.259315 8.54968 0.435607 8.37341L3.82984 4.97916Z" fill="#6FC72E" />
                                    </svg>

                                    </button>
                                </div>
                                <InsurerWisePremium data={premium2.slice(0, 6)} graphcol={'col-lg-5 col-md-6'} col={'col-lg-7 col-md-6'} />
                                <div className='mt-0 mt-md-5' style={{ borderBottomWidth: 0 }}>
                                    <hr />
                                    <div className='row gy-4 pt-2'>
                                        <div className='col-sm-6'>
                                            <div className='portfolio_loss_block p-0 h-100'>
                                                <p className='mb-2'>Total Premium amount</p>
                                                <p className='med text-black mb-0'>₹ 177,981,830</p>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='portfolio_loss_block border-0 p-0 h-100'>
                                                <p className='mb-2'>Number of Insurance Companies</p>
                                                <p className='med text-black mb-0'>10</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='custom_card p-4 h-100'>
                            <div className='portfolio_loss_block border-0 p-0'>
                                <div className='dashboard_header flex-row'>
                                    <h5 className='font16 sbold mb-0'>Product Wise Premium</h5>
                                    <button onClick={() => showInsuranceWisePopup("Product Wise Premium", images.widgets, "product")} className='font12' style={{ color: "#6FC72E", whiteSpace: "nowrap" }}>View all <svg width="6" height="10" className='ms-1' viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.82984 4.97916L0.414774 1.56407C0.243829 1.39314 0.157024 1.20298 0.154357 0.993572C0.151677 0.784183 0.238482 0.59135 0.414774 0.415072C0.591052 0.23878 0.782551 0.150635 0.989274 0.150635C1.196 0.150635 1.38985 0.241128 1.57084 0.422114L5.47563 4.32693C5.56752 4.42436 5.63643 4.52537 5.68236 4.62997C5.7283 4.73455 5.75127 4.85234 5.75127 4.98332C5.75127 5.11431 5.7283 5.23209 5.68236 5.33668C5.63643 5.44127 5.56752 5.53951 5.47563 5.63138L1.57084 9.5362C1.3952 9.71184 1.20615 9.79752 1.00369 9.79324C0.801245 9.78897 0.611885 9.6987 0.435607 9.52241C0.259315 9.34613 0.171169 9.15463 0.171169 8.94791C0.171169 8.74118 0.259315 8.54968 0.435607 8.37341L3.82984 4.97916Z" fill="#6FC72E" />
                                    </svg>

                                    </button>
                                </div>
                                <InsurerWisePremium data={graphData2.slice(0, 6)} graphcol={'col-lg-5 col-md-6'} col={'col-lg-7 col-md-6'} />
                                <div className='mt-0 mt-md-5' style={{ borderBottomWidth: 0 }}>
                                    <hr />
                                    <div className='row gy-4 pt-2'>
                                        <div className='col-sm-6'>
                                            <div className='portfolio_loss_block p-0 h-100'>
                                                <p className='mb-2'>Total Premium amount</p>
                                                <p className='med text-black mb-0'>₹ 177,981,830</p>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='portfolio_loss_block border-0 p-0 h-100'>
                                                <p className='mb-2'>Number of Insurance Companies</p>
                                                <p className='med text-black mb-0'>10</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='white-bg container_space'>
                <div className='dashboard_header'>
                    <h5 className='font16 sbold mb-0'>Policy Bifurcation</h5>
                </div>
                <div className='row gy-4 mb-4'>
                    {bifurcation.map((opt, i) => {
                        return (
                            <div className='col-md-6' key={i}>
                                <div className='custom_card p-3'>
                                    <Accordion className='custom_accordion' defaultActiveKey={i}>
                                        <Accordion.Item eventKey={i}>
                                            <button className='accordion-button collapsed' type='button'>
                                                <button className='font14 med mb-0 text-start' style={{ flexGrow: 1 }} onClick={() => showPolicyPopup(opt.title, opt.icon)}>
                                                    <img src={opt.icon} alt="" className='me-3' />
                                                    {opt.title}
                                                </button>
                                                <h5 className='font14 sbold text-black mb-0'>
                                                    {opt.total}
                                                    <CustomToggle eventKey={i}><img src={images.navigate_next} alt="" className='ms-2 arrow_icon' style={{ verticalAlign: "inherit" }} /></CustomToggle>
                                                </h5>
                                            </button>
                                            <Accordion.Body>
                                                <ul className='insights_list'>
                                                    {opt.data.map((data, ind) => {
                                                        return (
                                                            <li key={ind} className='py-2'>
                                                                <h6 className='font12 med mb-0'>
                                                                    <img src={data.icon} alt="" className='me-3' />
                                                                    {data.title}
                                                                </h6>
                                                                <span className='custom_badge'>
                                                                    {data.total}
                                                                </span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>

            {show ?
                <PopupAside position={"right"} size={"lg"} close={handleClose}>
                    {activePolicy &&
                        <div>
                            <div className='dashboard_header flex-row'>
                                <h5 className='font16 sbold mb-0'><img src={icon} className='me-2' alt='' /> {title}</h5>
                                <button onClick={handleClose}><img src={images.close} className='' alt='' /></button>
                            </div>
                            <ActivePolicies data={activePolicies} />
                        </div>
                    }
                    {insuranceWisePopup &&
                        <div>
                            <div className='dashboard_header flex-row'>
                                <h5 className='font16 sbold mb-0'><img src={icon} className='me-2' alt='' height={20} /> {title}</h5>
                                <button><img src={images.close} className='' alt='' /></button>
                            </div>
                            <InsurerWisePremiumPopup data={type == "product" ? graphData2.slice(0, 6) : premium.slice(0, 6)} data2={type == "product" ? graphData2 : premium2} />
                        </div>
                    }
                </PopupAside>
                : null
            }
        </div>
    )
}
