import React, { useState } from 'react'
import "./Reports.css";
import ReportData from './ReportData';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import images from '../images';

export default function MyDocuments() {
    const th1 = [
        { key: "name", name: "Certificate Title" },
        { key: "doctitle", name: "Document Title" },
        { key: "scope", name: "Scope" },
        { key: "product", name: "Policy & Product  group" },
        { key: "date", name: "Validity" },
        { key: "status", name: "Status" },
        { key: "", name: "" }
    ]
    const th2 = [
        { key: "name", name: "Client Name" },
        { key: "product", name: "Product Name" },
        { key: "doc", name: "Document Type" },
        { key: "policy", name: "Policy No" },
        { key: "date", name: "Policy period" },
        { key: "status", name: "Status" },
        { key: "", name: "" }
    ]
    const th3 = [
        { key: "name", name: "Client Name" },
        { key: "product", name: "Product Name" },
        { key: "doc", name: "Document Type" },
        { key: "policy", name: "Policy No" },
        { key: "date", name: "Endorsement Date" },
        { key: "status", name: "Status" },
        { key: "", name: "" }
    ]
    const tableData = [
        { name: "Asset Register DEMO", doctitle: "Premium Receipt", scope: "PORTFOLIO", date: "15 July, 2024", product: "Liability", status: "Active" },
        { name: "Risk Inspection report", doctitle: "Policy Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Marine", status: "Inactive" },
        { name: "Risk Management DEMO", doctitle: "Endorsement Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Health, Marine", status: "Active" },
        { name: "Asset Register DEMO", doctitle: "Premium Receipt", scope: "PORTFOLIO", date: "15 July, 2024", product: "Property", status: "Inactive" },
        { name: "Asset Report", doctitle: "Policy Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Health, Marine", status: "Active" },
        { name: "Certificate", doctitle: "Endorsement Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Property", status: "Inactive" },
        { name: "Asset Certificate", doctitle: "Policy Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Health, Marine", status: "Active" },
        { name: "Asset Register DEMO", doctitle: "Premium Receipt", scope: "PORTFOLIO", date: "15 July, 2024", product: "Property", status: "Inactive" },
        { name: "Risk Inspection report", doctitle: "Policy Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Health, Marine", status: "Active" },
        { name: "Risk Management DEMO", doctitle: "Endorsement Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Property", status: "Inactive" },
        { name: "Asset Register DEMO", doctitle: "Premium Receipt", scope: "PORTFOLIO", date: "15 July, 2024", product: "Health, Marine", status: "Active" },
        { name: "Asset Report", doctitle: "Policy Certificate", scope: "PORTFOLIO", date: "15 July, 2024", product: "Property", status: "Inactive" },
    ]
    const tableData2 = [
        { name: "Alena Gouse", doc: "Premium Receipt", policy: "ICICI3584531545", date: "15 July, 2024", product: "Silver Travel Insurance plan", status: "Active" },
        { name: "Tatiana Donin", doc: "Policy Certificate", policy: "LIC3584531545", date: "15 July, 2024", product: "Platinum Life Insurance plan", status: "Active" },
        { name: "Terry Stanton", doc: "Endorsement Certificate", policy: "MAX3584531545", date: "15 July, 2024", product: "Max Term Life Insurance", status: "Active" },
        { name: "Insurance Certificate Title", doc: "Endorsement Certificate", policy: "HDFC3584531545", date: "15 July, 2024", product: "Gold Term life Insurance", status: "Active" },
        { name: "Alena Gouse", doc: "Premium Receipt", policy: "ICICI3584531545", date: "15 July, 2024", product: "Silver Travel Insurance plan", status: "Active" },
        { name: "Tatiana Donin", doc: "Policy Certificate", policy: "LIC3584531545", date: "15 July, 2024", product: "Platinum Life Insurance plan", status: "Active" },
        { name: "Terry Stanton", doc: "Endorsement Certificate", policy: "MAX3584531545", date: "15 July, 2024", product: "Max Term Life Insurance", status: "Active" },
        { name: "Insurance Certificate Title", doc: "Endorsement Certificate", policy: "HDFC3584531545", date: "15 July, 2024", product: "Gold Term life Insurance", status: "Active" },

    ]
    const tableData3 = [
        { name: "Alena Gouse", doc: "Premium Receipt", policy: "ICICI3584531545", date: "21 Jan, 2023", product: "Silver Travel Insurance plan", status: "Active" },
        { name: "Tatiana Donin", doc: "Policy Certificate", policy: "LIC3584531545", date: "21 Jan, 2023", product: "Platinum Life Insurance plan", status: "Active" },
        { name: "Terry Stanton", doc: "Endorsement Certificate", policy: "MAX3584531545", date: "21 Jan, 2023", product: "Max Term Life Insurance", status: "Active" },
        { name: "Insurance Certificate Title", doc: "Endorsement Certificate", policy: "HDFC3584531545", date: "21 Jan, 2023", product: "Gold Term life Insurance", status: "Active" },
        { name: "Alena Gouse", doc: "Premium Receipt", policy: "ICICI3584531545", date: "21 Jan, 2023", product: "Silver Travel Insurance plan", status: "Active" },
        { name: "Tatiana Donin", doc: "Policy Certificate", policy: "LIC3584531545", date: "21 Jan, 2023", product: "Platinum Life Insurance plan", status: "Active" },
        { name: "Terry Stanton", doc: "Endorsement Certificate", policy: "MAX3584531545", date: "21 Jan, 2023", product: "Max Term Life Insurance", status: "Active" },
        { name: "Insurance Certificate Title", doc: "Endorsement Certificate", policy: "HDFC3584531545", date: "21 Jan, 2023", product: "Gold Term life Insurance", status: "Active" },

    ]
    const tabs = [
        { name: "Certificates", type: "Certificates", render: <ReportData data={tableData} th={th1} /> },
        { name: "Policy", type: "Policy", render: <ReportData data={tableData2} th={th2} /> },
        { name: "Endorsements", type: "Endorsements", render: <ReportData data={tableData3} th={th3} /> },
    ]
    const [activeTab, setactiveTab] = useState("Certificates")

    const policyType = (type) => {
        setactiveTab(type)
    }
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [active, setactive] = useState({ value: 'All', label: 'All' });

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const selectoptions = [
        { value: 'All', label: 'All' },
        { value: 'FY', label: 'Financial Year' },
        { value: 'CY', label: 'Calendar year' },
        { value: 'Custom', label: 'Custom' },
    ]
    const policyoptions = [
        { value: 'Health Insurance', label: 'Health Insurance' },
        { value: 'Term Insurance', label: 'Term Insurance' },
        { value: 'Life Insurance', label: 'Life Insurance' },
        { value: 'Motor Insurance', label: 'Motor Insurance' },
        { value: 'Property Insurance', label: 'Property Insurance' },
    ]
    const statusoptions = [
        { value: 'All', label: 'All' },
        { value: 'Drafted', label: 'Drafted' },
        { value: 'Published', label: 'Published' },
        { value: 'Archived', label: 'Archived' },
    ]
    const FY = [
        { value: '2020-2021', label: '2020-2021' },
        { value: '2021-2022', label: '2021-2022' },
        { value: '2022-2023', label: '2022-2023' },
    ]

    const CY = [
        { value: '2024', label: '2024' },
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' },
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
        { value: '2019', label: '2019' },
    ]
    const title = [
        { value: 'All', label: 'All' },
        { value: 'Premium Receipt', label: 'receipt' },
        { value: 'Policy Certificate', label: 'policy-certificate' },
        { value: 'Endorsement Certificate', label: 'endorsement certificate' },
    ]

    const [showFilters, setshowFilters] = useState(false)

    const onSelect = (data) => {
        setactive(data)
    }
    const clearfilter = () => {
        setactive("")
    }
    const clickFilters = () => {
        setshowFilters(!showFilters)
    }
    return (
        <div>

            <div className='white-bg  container_space   '>
                <div className=' dashboard_header '>
                    <div className='row w-100 justify-content-between align-items-center'>
                        <div className='col-md-6'><h5 className="font16 sbold mb-0">Documents</h5></div>
                        <div className='col-md-4'>
                            <div className='dashboard_card_search'>
                                <div className='private_header_search'>
                                    <div className='header_search_bar w-100'>
                                        <img src={images.search} alt='' width={13} />
                                        <input type='text' placeholder={`Search ${activeTab === "Certificates" ? "Certificates" : activeTab === "Policy" ? "Policy" : "Endorsements"} `} className='font12' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className=''>
                    <div className='row justify-content-between'>
                        <div className='col-12'>
                            <div className='dashboard_card'>
                                <div className='dashboard_card_header tabBorder pb-0 flex-row'>
                                    <div>
                                        {tabs.map((tab, i) => {
                                            return (
                                                <button key={i} onClick={() => policyType(tab.type)} className={`product_tab font14 sbold mb-0 ${activeTab == tab.type ? "active" : ""}`}>{tab.name}</button>
                                            )
                                        })}
                                    </div>

                                    <button onClick={clickFilters} className='d-block d-md-none'>
                                        <img src={images.filter_alt} alt='' />
                                    </button>
                                </div>
                                <div className='position-relative'>
                                    <div className={`doc_filter_outer ${showFilters ? "show" : ""}`}>
                                        <div className='customSelectBoxRow report_filters d-flex align-items-center'>
                                            <div className='customSelectBox '>
                                                <p className='font12'>Document title</p>
                                                <div className='custom_select w-100'>
                                                    <Select
                                                        options={title}
                                                        value={title[0].value}
                                                        className='w-100'
                                                        classNamePrefix="select2"
                                                        onChange={onSelect}
                                                        menuPlacement='auto'
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </div>
                                            {activeTab === "Certificates" ?
                                                <div className='customSelectBox '>
                                                    <p className='font12'>Period</p>
                                                    <div className='custom_select w-100'>
                                                        <Select
                                                            options={selectoptions}
                                                            value={active || selectoptions[0].value}
                                                            className='w-100'
                                                            classNamePrefix="select2"
                                                            onChange={onSelect}
                                                            menuPlacement='auto'
                                                            isSearchable={false}
                                                        />
                                                        {active.value == "FY" ?
                                                            <Select
                                                                options={FY}
                                                                defaultValue={FY[0].value}
                                                                className='w-100  '
                                                                classNamePrefix="select2"
                                                                menuPlacement='auto'
                                                                isSearchable={false}
                                                            />
                                                            : null
                                                        }
                                                        {active.value == "CY" ?
                                                            <Select
                                                                options={CY}
                                                                defaultValue={CY[0].value}
                                                                className='w-100  '
                                                                classNamePrefix="select2"
                                                                menuPlacement='auto'
                                                                isSearchable={false}
                                                            />
                                                            : null
                                                        }
                                                        {active.value == "Custom" ?
                                                            <div className='d-flex common_btn btn_light2  '>
                                                                <img src={images.event_note} alt='' width={12} className='me-2' />
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={onChange}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    selectsRange
                                                                    placeholderText='Select Range'
                                                                    className='custom_range'
                                                                    dateFormat="dd/MM/yy"
                                                                />
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div> : null}
                                            {/*  */}
                                            <div className='customSelectBox '>
                                                <p className='font12'>Policy type</p>
                                                <div className='custom_select w-100'>
                                                    <Select
                                                        options={policyoptions}
                                                        defaultValue={policyoptions[0].value}
                                                        className='w-100'
                                                        classNamePrefix="select2"
                                                        menuPlacement='auto'
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='customSelectBox '>
                                                <p className='font12'>Product type</p>
                                                <div className='custom_select w-100'>
                                                    <Select
                                                        options={policyoptions}
                                                        defaultValue={policyoptions[0].value}
                                                        className='w-100'
                                                        classNamePrefix="select2"
                                                        menuPlacement='auto'
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </div>
                                            {/*  */}
                                            {activeTab === "Certificates" ?
                                                <div className='customSelectBox '>
                                                    <p className='font12'>Status</p>
                                                    <div className='custom_select w-100'>
                                                        <Select
                                                            options={statusoptions}
                                                            defaultValue={statusoptions[0].value}
                                                            className='w-100'
                                                            classNamePrefix="select2"
                                                            menuPlacement='auto'
                                                            isSearchable={false}
                                                        />
                                                    </div>
                                                </div> : null}

                                        </div>
                                    </div>
                                    {tabs.filter((x) => x.type == activeTab).map((opt) => {
                                        return (
                                            <div key={opt.type}>
                                                {opt.render}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
