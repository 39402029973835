
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import "react-datepicker/dist/react-datepicker.css";
import './components/private/common/common.css';
import Header from './components/public/Header/Header';
import Footer from './components/public/Footer/Footer';
import Home from './components/public/Home/Home';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './PrivateAuth';
import PrivateHeader from './components/private/PrivateHeader/PrivateHeader';
import AdminHome from './components/private/Home';
import ForgotPasword from './components/public/ForgotPasword/ForgotPasword';
import Policy from './components/private/Policy';
import Claims from './components/private/Claims';
import Reports from './components/private/Reports';
import Regulations from './components/private/Regulations';
import MyDocuments from './components/private/MyDocuments'; 
import CustomerSupport from './components/public/CustomerSupport/CustomerSupport';
import Call from './components/public/CustomerSupport/Call';
import Events from './components/private/Events/index'

import PrivateSideBar from './components/private/PrivateSideBar/PrivateSideBar';
import './App.css';

export const isLogIn = () => {
  if (localStorage.getItem("AUTH")) {
    return { isToken: true };
  } else {
    return { isToken: false };
  }
};

export const logout = () => {
  localStorage.clear()
  window.open("/", "_self")
}


function App() {
  return (
    <div className="App">
      <Router>
        {!isLogIn().isToken ?
          <>
            <Header />
            <main className='auth_outer'>
              <Routes>
                <Route exact path='/' element={<PrivateRoute />}>
                  <Route path={"/"} element={<Home />} />
                  <Route path={"/forgot-password"} element={<ForgotPasword />} />
                  <Route path={"/support"} element={<CustomerSupport />} />
                  <Route path={"/support/call"} element={<Call />} />
                </Route>
              </Routes>
            </main>
            <Footer />
          </>
          :
          <div className='dashboard_outer mainHolder'> 
              <PrivateSideBar />
              <div className='mainHolder__content'>
                <PrivateHeader />
                <Routes>
                  <Route exact path='/' element={<PublicRoute />}>
                    <Route exact path={"/"} element={<AdminHome />} />
                    <Route exact path={"/home"} element={<AdminHome />} />
                    <Route exact path={"/policy"} element={<Policy />} />
                    <Route exact path={"/claims"} element={<Claims />} />
                    <Route exact path={"/reports"} element={<Reports />} />
                    <Route exact path={"/regulations"} element={<Regulations />} />
                    <Route exact path={"/my-documents"} element={<MyDocuments />} /> 
                    <Route exact path={"/events"} element={<Events />} />
                  </Route>
                </Routes>
              </div> 
          </div>
        }
      </Router>

    </div>
  );
}

export default App;
