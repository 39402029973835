import React from 'react'
import Sidebar from './Sidebar'
import ReportData from './ReportData';

export default function Regulations() {
    const tableData = [
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
        {name: "Regulations.pdf", date: "15 September, 2023"},
    ]
    return (
        <div>
            <Sidebar />
            <div className='dashboard_container'>
                <div className='container-fluid'>
                    <div className='row justify-content-between'>
                        <div className='col dashboard_card_aside'>
                        </div>
                        <div className='col '>
                            <ReportData data={tableData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
