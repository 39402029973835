import React, { useState } from 'react'
import Sidebar from './Sidebar'
import "./Reports.css";
import ReportData from './ReportData';
import images from '../images';

const th1 = [
    { key: "name", name: "Certificate Title" },
    { key: "doctitle", name: "Document Title" },
    { key: "scope", name: "Scope" },
    { key: "product", name: "Policy & Product  group" },
    { key: "date", name: "Validity" },
    { key: "status", name: "Status" },
    { key: "", name: "" }
]
const th2 = [
    { key: "name", name: "Client Name" },
    { key: "product", name: "Product Name" },
    { key: "doc", name: "Document Type" },
    { key: "policy", name: "Policy No" },
    { key: "date", name: "Policy period" },
    { key: "status", name: "Status" },
    { key: "", name: "" }
]

const tableData = [
    { name: "Cirkulära affärsmodeller lean startup. ", date: "15 September, 2023" },
    { name: "Pinterest vilanas, trevaliga cyntos fulfillment. ", date: "15 September, 2023" },
    { name: "Agnostilogi exode engraf. ", date: "15 September, 2023" },
    { name: "Gura nyfäns. ", date: "15 September, 2023" },
    { name: "Nilödat inbound marketing, otinade cirkulär handel. ", date: "15 September, 2023" },
    { name: "Innovation conscious consumerism tills tuck i romuda det vill säga terragraf. ", date: "15 September, 2023" },
    { name: "Elektroskop pure players, drop shipping psykotopi. ", date: "15 September, 2023" },
    { name: "Kluster tegyn, sasa geofencing. ", date: "15 September, 2023" },
    { name: "Den nya ekonomin metataggar logotris. ", date: "15 September, 2023" },
    { name: "Sensorteknik science park computer vison stereodiktisk. ", date: "15 September, 2023" },
    { name: "Reatotal cynskop innovatör. ", date: "15 September, 2023" },
    { name: "Demitik new retail oaktat neopod terraism. ", date: "15 September, 2023" },
    { name: "Prosofi polytos. Du kan vara drabbad. ", date: "15 September, 2023" },
]
const tableData2 = [
    { name: "Cirkulära affärsmodeller lean startup. ", date: "16 September, 2023" },
    { name: "Pinterest vilanas, trevaliga cyntos fulfillment. ", date: "16 September, 2023" },
    { name: "Agnostilogi exode engraf. ", date: "16 September, 2023" },
    { name: "Gura nyfäns. ", date: "16 September, 2023" },
    { name: "Nilödat inbound marketing, otinade cirkulär handel. ", date: "16 September, 2023" },
    { name: "Innovation conscious consumerism tills tuck i romuda det vill säga terragraf. ", date: "16 September, 2023" },
    { name: "Elektroskop pure players, drop shipping psykotopi. ", date: "16 September, 2023" },
    { name: "Kluster tegyn, sasa geofencing. ", date: "16 September, 2023" },
    { name: "Den nya ekonomin metataggar logotris. ", date: "16 September, 2023" },
    { name: "Sensorteknik science park computer vison stereodiktisk. ", date: "16 September, 2023" },
    { name: "Reatotal cynskop innovatör. ", date: "16 September, 2023" },
    { name: "Demitik new retail oaktat neopod terraism. ", date: "16 September, 2023" },
    { name: "Prosofi polytos. Du kan vara drabbad. ", date: "16 September, 2023" },
]


const tabs = [
    { name: "Reports & Updates", type: "reports", render: <ReportData data={tableData} /> },
    { name: "Regulations", type: "regulation", render: <ReportData data={tableData2} /> },
]
export default function Reports() {

    const [activeTab, setactiveTab] = useState("reports")

    const policyType = (type) => {
        setactiveTab(type)
    }

    return (
        <div className=' white-bg  container_space '>
            <div className=' dashboard_header '>
                <div className='row w-100 justify-content-between align-items-center'>
                    <div className='col-md-6'><h5 className="font16 sbold mb-0">Reports</h5></div>
                    <div className='col-md-4'>
                        <div className='dashboard_card_search'>
                            <div className='private_header_search'>
                                <div className='header_search_bar w-100'>
                                    <img src={images.search} alt='' width={13} />
                                    <input type='text' placeholder={`Search ${activeTab === "reports" ? "Reports & Updates" : "Regulations"} `} className='font12' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className=' '>
                <div className='dashboard_card_header tabBorder pb-0'>
                    <div>
                        {tabs.map((tab, i) => {
                            return (
                                <button key={i} onClick={() => policyType(tab.type)} className={`product_tab font14 sbold mb-0 ${activeTab == tab.type ? "active" : ""}`}>{tab.name}</button>
                            )
                        })}
                    </div>

                </div>

                {/* <div className=' '>
                    <ReportData data={tableData} />
                </div> */}
                {tabs.filter((x) => x.type == activeTab).map((opt) => {
                    return (
                        <div key={opt.type}>
                            {opt.render}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
