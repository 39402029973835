import React, { useState } from 'react'
import images from '../images'
import Select from 'react-select'

export default function ServiceCalander(props) {
    const [activemonth, setactivemonth] = useState("1")
    const clicktab = (obj) => {
        setactivemonth(obj.id)
    }
    const changeTab = (obj) => {
        setactivemonth(obj.id)
    }
    const CY = [
        { value: '2024', label: '2024' },
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' },
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
        { value: '2019', label: '2019' },
    ]
    return (
        <div className='dashboard_card'>
            <div className='service_calendar d-flex'>
                <div className='custom_select dark me-2' style={{minWidth: 120}}>
                    <Select
                        options={CY}
                        defaultValue={CY[0].value}
                        className='w-100'
                        classNamePrefix="select"
                        menuPlacement='auto'
                        isSearchable={false}
                        placeholder="Select year"
                    />
                </div>
                <div className='custom_select d-block d-sm-none dark me-2' style={{minWidth: 120}}>
                    <Select
                        options={props.data}
                        defaultValue={props.data[0].value}
                        className='w-100'
                        classNamePrefix="select"
                        menuPlacement='auto'
                        isSearchable={false}
                        placeholder="Select month"
                        onChange={changeTab}
                    />
                </div>
                <div className='service_tabs d-none d-sm-flex'>
                    {props.data.map((data, ind) => {
                        return (
                            <button key={ind} className={data.id == activemonth ? "active" : ""} onClick={() => clicktab(data)}>{data.label}</button>
                        )
                    })}
                </div>
            </div>
            {/* <hr className='mb-0' /> */}
            {props.data.filter((x) => x.id == activemonth).map((data, ind) => {
                return (
                    <div className="policy_table w-100">
                        <table className='table sort_table'>
                            <thead>
                                <tr>
                                    {props.th.map((opt, i) => {
                                        return (
                                            <th key={i} className={opt.key == "" ? "empty" : ""}>{opt.name}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.map((opt, i) => {
                                    return (
                                        // date: "01 Jan", 
                                        // activity: {title:"Activity Title ", desc: "Etnotiv miligt de innovationsprocess, ninening. Fonoplastisk endograf ansvarsfull, prime now. "},
                                        // owner:"Maria Passaquindici Arcand",
                                        // scope:"Accident",
                                        // policy:"Life Insurance",
                                        // :"Completed",



                                        <tr key={i}>
                                            <td >
                                                <p className='med mb-0'>{opt.date}</p>
                                            </td>
                                            <td style={{ width: "300px" }} >

                                                <div className='font-12 semibold text-black'>{opt.activity.title}</div>
                                                <p className='med  mb-0'>{opt.activity.desc}</p>

                                            </td>
                                            <td >
                                                <p className='med mb-0 text-black'>{opt.owner}</p>
                                            </td>

                                            <td >
                                                <p className='med mb-0'>{opt.scope}</p>
                                            </td>
                                            <td >
                                                <p className='med mb-0'>{opt.policy}</p>
                                            </td>
                                            <td >
                                                <p className='med mb-0 text-black'>{opt.status}</p>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )
            })}
        </div>
    )
}
