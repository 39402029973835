import React from 'react'

export default function PopupAside(props) {
    return (
        <div className='popup_aside'>
            <div className='popup_aside_backdrop' onClick={props.close}></div>
            <div className={`popup_aside_inner ${props.position} ${props.size}`}>
                {props.children}
            </div>
        </div>
    )
}
